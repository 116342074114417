// import actions from './actions';

const initState = {
  connected: false,
};

export default function mqttReducer(state = initState, action) {
  // // console.log('ACTION_MQTT', action);
  switch (action.type) {
    // case action.SETTING_VIEW:
    //   return {
    //     ...state,
    //     view: action.view,
    //   };
    default:
      return state;
  }
}
