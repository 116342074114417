import axios from 'axios';
import { getToken } from '@iso/lib/helpers/utility';
// import { getCookie } from '@iso/lib/helpers/session';
import { put } from 'redux-saga/effects';
import actions from '../../redux/auth/actions';
import * as cookie from 'js-cookie';
// const history = createBrowserHistory();
// const fakeApiCall = false; // auth0 or express JWT
const timeout = 30000;
const apiUrl = process.env.REACT_APP_API_URL;
// const bearer = process.env.BEARER;
// const systemId = process.env.REACT_APP_SYSTEM_ID;

let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export function* get(uri, data, page, sort, token) {
    // this.checkToken();
    const cookieData = cookie.get('token');
    const sessionToken = getToken().get('idToken') ? getToken().get('idToken') : token ? token : cookieData ? cookieData : null;
    // if (token) {
    headers = {
        ...headers,
        authorization: `Bearer ${sessionToken}`,
    };
    // }
    if (page) {
        headers = {
            ...headers,
            range: `${page.page || 1}-${page.limit || 10}`,
        };
    }
    if (!sort) {
        sort = {
            sort: 'asc',
            sortBy: 'createdAt',
        };
    }
    const params = data ? toParams(data) : '';
    const sortField = `&sort=${sort.sort === 'asc' ? '' : '-'}${sort.sortBy}`;
    return yield fetch(`${apiUrl}${uri}?${params}${sortField}`, {
        headers: headers,
        method: 'GET',
    });
}

export function* post(uri, data) {
    headers = {
        ...headers,
    };
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'POST',
        data,
    });
}
export function* postWithToken(uri, data, page, token) {
    const cookieData = cookie.get('token');
    const sessionToken = getToken().get('idToken') ? getToken().get('idToken') : token ? token : cookieData ? cookieData : null;
    console.log('COOKIE', cookieData);
    if (page) {
        headers = {
            ...headers,
            authorization: `Bearer ${sessionToken}`,
            Range: `${page.page || 1}-${page.limit || 50}`,
        };
    } else {
        headers = {
            ...headers,
            authorization: `Bearer ${sessionToken}`,
        };
    }
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'POST',
        data,
    });
}
export function* putOtp(uri, data, token) {
    headers = {
        ...headers,
        authorization: `Bearer ${token}`,
    };
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'PUT',
        data,
    });
}
export function* postBlob(uri, data) {
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'POST',
        data,
        responseType: 'blob',
    });
}
// export function* postArrayBuffer(uri, data) {
//     return yield fetch(`${apiUrl}${uri}`, {
//         headers: headers,
//         method: 'POST',
//         data,
//         responseType: 'arraybuffer',
//     });
// }
export function* PUT(uri, data) {
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'PUT',
        data,
    });
}

export function* DELETE(uri, data) {
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'DELETE',
        data,
    });
}

function toParams(data) {
    const url = Object.keys(data).map((k) => {
        return `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;
    }).join('&');
    return url;
}

function* forbidden() {
    yield localStorage.setItem('forbidToken', 1);
    yield put({
        type: actions.CHECK_AUTHORIZATION,
    });
}

export function* fetch(url, options) {
    // try {
    const result = yield axios.request({
        ...options,
        url,
        baseURL: apiUrl,
        timeout: timeout,
        headers: headers
    }).then((res) => {
        return res;
    }).catch((error) => {
        return error
    })
    if (result.status) {
        return result;
    } else {
        if (result.response.status === 401 || result.response.status === 403) {
            yield forbidden();
        } else {
            if (result.response) {
                return result.response;
            }
        }
    }
}