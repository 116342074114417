import { all, takeEvery, call, put, fork, take, select } from 'redux-saga/effects';
// import { post } from '@iso/lib/helpers/resApiRequestor';
import { eventChannel } from 'redux-saga';
import actions from './actions';
// import authAction from '../auth/actions';
// import financialsAction from '../financials/actions';
// import dashBoardAction from '../dashboard/actions';
// import merchantAction from '../merchant/actions';
// import trxAction from '../transactions/actions';
import corpAcctAction from '../corporateAccount/actions';
// import merchantActions from '../merchants/actions';
// import * as mqttPaho from 'paho.mqtt.js';
import * as mqtt from 'mqtt';
// import { postBlob } from '@iso/lib/helpers/resApiRequestor';
import * as _ from 'lodash';

const { messageArrived } = corpAcctAction;
const hostMqtt1 = process.env.REACT_APP_MQTT_HOST1;
const hostMqtt2 = process.env.REACT_APP_MQTT_HOST2;
const protocol = process.env.REACT_APP_MQTT_PROTOCOL;
const port = process.env.REACT_APP_MQTT_PORT;
const username = process.env.REACT_APP_MQTT_USER;
const password = process.env.REACT_APP_MQTT_PASS;
const url = [
  {
    protocol,
    host: hostMqtt1,
    port,
  },
  {
    protocol,
    host: hostMqtt2,
    port,
  },
];
const options = {
  clientId: '',
  clean: true,
  keepalive: 60,
  connectTimeout: 4000,
  rejectUnauthorized: false,
  username,
  password,
  servers: url,
};
let client;
// let newPayload;
export function* connectMQTT() {
  yield takeEvery('CONNECT', function* () {
    // // console.log('MQTT_SAGA');
    options.clientId = 'campfire_ui' + Math.random().toString(16).substr(2, 8);
    try {
      // console.log('CONNECTING MQTT', options);
      client = mqtt.connect(options);
      client.on('error', (error) => {
        // // console.log('ERROR', error);
      });

      client.on('connect', () => {
        // console.log('ON CONNECT');
      });

      const eventResult = yield call(createMQTTEventHandler, client);
      // // console.log(eventResult)

      while (true) {
        try {
          // An error from socketChannel will cause the saga jump to the catch block
          // const payload = yield take(eventResult)
          yield take(eventResult)
          // console.log('payload', payload)
        } catch (err) {
          console.error('socket error:', err)
        }
      }

    } catch (e) {
      // // console.log(e.message)
      yield put({
        type: actions.CONNECT_FAILED
      });
    }
  });
}
export function* connectToMqtt() {
  yield takeEvery('CONNECT_MQTT', function* ({ payload }) {
    console.log('CHECK MQTT PAYLOAD', payload);
    options.clientId = `campfire_ui_${payload.module}${Math.random().toString(16).substr(2, 8)}`;
    // const topicDlMerchantReport = `/download/merchant/${payload.dlRequest}`
    // // console.log('topicDlMerchantReport', topicDlMerchantReport);
    // // console.log('TOPIC_PAYLOAD', payload);
    // // console.log('options', options);

    try {
      console.log('CONNECTING MQTT');
      client = mqtt.connect(options);
      client.on('error', (error) => {
        // // console.log('ERROR', error);
      });

      client.on('connect', () => {
        console.log('ON CONNECT', payload.topic);
        client.subscribe([payload.topic]);
      });

      const eventResult = yield call(createMQTTEventHandlerNew, client);
      console.log('EVENT_RESULT', eventResult)

      while (true) {
        // // console.log('ifMQTTisTrue', true);
        try {
          // An error from socketChannel will cause the saga jump to the catch block
          const result = yield take(eventResult)
          const state = yield select();
          console.log('MQTT_PAYLOAD', result)
          // // console.log('PAYLOAD', payload);
          if (result.type) {
            // DITO MO ILAGAY PRE UNG YIELD PARA MALAGAY MO SA REDUX
            console.log('CHECK RESULT', result);
            if (payload.module === 'transaction/download') {
              const currentData = state.CorporateAccount.downloadList;
              console.log('currentData', currentData);
              const updatedList = _.reject(currentData, { Id: result.payload.Id });
              // console.log('updatedList', updatedList);
              // console.log('RESULT_PAYLOAD', result.payload);
              if (result.payload.status !== 'No Transaction Found!') {
                const newList = _.union([result.payload], updatedList);
                console.log('newListValue', newList);
                // console.log('newList', updatedList);
                yield put({
                  type: corpAcctAction.FETCHED_DOWNLOAD_LIST,
                  payload: newList,
                });
              } else {
                yield put({
                  type: corpAcctAction.FETCHED_DOWNLOAD_LIST,
                  payload: updatedList,
                });
                // yield put({
                //   type: trxAction.DOWNLOAD_STATUS,
                //   payload: 'No Transaction Found!',
                // });
              }
            }
          }
          // // console.log(eventResult)
        } catch (err) {
          console.error('socket error:', err)
        }
      }
    } catch (e) {
      console.log('catchErrorMessage', e.message)
      yield put({
        type: actions.CONNECT_FAILED
      });
    }
  });
}

function createMQTTEventHandler(client) {
  return eventChannel(emitter => {
    client.on('message', (topic, message) => {
      // const newMessage = message.toString();
      // console.log('MESSAGE_TOPIC', topic);
      const newPayload = JSON.parse(message.toString());
      // console.log('PAYLOAD_MESSAGE', newPayload);
      emitter(messageArrived(newPayload))
    });
    return () => false;
  });
}
function createMQTTEventHandlerNew(client) {
  // console.log('MQTT_EVENT');
  return eventChannel(emitter => {
    client.on('message', (topic, message) => {
      // const newMessage = message.toString();
      // console.log('MESSAGE_TOPIC', topic);
      // console.log('MESSAGE_DATA', message);
      const newPayload = JSON.parse(message.toString());
      // console.log('PAYLOAD_MESSAGE', newPayload);
      emitter(messageArrived(newPayload));
      client.unsubscribe([topic]);
    });
    return () => false;
  });
}
// function downloadExcel(data) {
//   const values = {
//     filename: data
//   };
//   return postBlob(`transactions/download/billing_report/`, values);
// }
// function getPDF(data) {
//   const file = {
//     filename: data,
//   }
//   return postBlob(`billings/get_pdf`, file);
// }
// function getOneTimeAnnualPDF(data) {
//   const file = {
//     filename: data,
//   }
//   return postBlob(`billings/get_pdf/one_time_annual`, file);
// }
// function getSettlementPDF(data) {
//   const file = {
//     filename: data,
//   }
//   return postBlob(`settlementpdf/get_pdf`, file);
// }
// function* messageArrived(payload) {
//   // console.log('MESSAGE_ARRIVED');
//   yield put({
//     type: trxAction.MQTT_TRX,
//     mqttTrx: payload,
//   });
// }
// function fetchTrxDetails(data) {
//   return post(`transactions/transaction_details`, data);
// }
export default function* rootSaga() {
  yield all([
    fork(connectMQTT),
    fork(connectToMqtt),
    // fork(mqttLoginValidator),
  ]);
}
