const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  FORGOT_ERROR: 'FORGOT_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SUBMODULES: 'SUBMODULES',
  SET_LOADING: 'SET_LOADING',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_DONE: 'FORGOT_PASSWORD_DONE',
  SIGNUP: 'SIGNUP',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  RESET_SECOND_TIMER: 'RESET_SECOND_TIMER',
  ON_RESET_TIMER: 'ON_RESET_TIMER',
  LOGIN_VALIDATOR: 'LOGIN_VALIDATOR',
  FORBIDDEN_REQUEST: 'FORBIDDEN_REQUEST',
  KICKED_OUT: 'KICKED_OUT',
  KICKED_OUT_CLEAR: 'KICKED_OUT_CLEAR',
  // GET RESET PASSWORD URL
  FETCH_RESET_PASSWORD_URL: 'FETCH_RESET_PASSWORD_URL',
  FETCHING_RESET_PASSWORD_URL: 'FETCHING_RESET_PASSWORD_URL',
  FETCHED_RESET_PASSWORD_URL: 'FETCHED_RESET_PASSWORD_URL',
  FETCH_RESET_PASSWORD_URL_FAILED: 'FETCH_RESET_PASSWORD_URL_FAILED',

  SET_OTP_BANNER: 'SET_OTP_BANNER',
  SAVE_AGREE_OTP: 'SAVE_AGREE_OTP',
  SAVING_AGREE_OTP: 'SAVING_AGREE_OTP',
  SAVED_AGREE_OTP: 'SAVED_AGREE_OTP',
  SAVE_AGREE_OTP_FAILED: 'SAVE_AGREE_OTP_FAILED',

  REQUEST_LOGIN_OTP: 'REQUEST_LOGIN_OTP',
  REQUESTING_LOGIN_OTP: 'REQUESTING_LOGIN_OTP',
  REQUESTED_LOGIN_OTP: 'REQUESTED_LOGIN_OTP',
  ERROR_REQUEST_LOGIN_OTP: 'ERROR_REQUEST_LOGIN_OTP',
  LOGIN_DATA: 'LOGIN_DATA',
  SET_SHOW_OTP_MODAL: 'SET_SHOW_OTP_MODAL',

  VALIDATE_OTP: 'VALIDATE_OTP',
  VALIDATING_OTP: 'VALIDATING_OTP',
  VALIDATED_OTP: 'VALIDATED_OTP',
  VALIDATE_OTP_FAILED: 'VALIDATE_OTP_FAILED',
  OTP_AUTH_TOKEN: 'OTP_AUTH_TOKEN',
  CLEAR_OTP_MESSAGE: 'CLEAR_OTP_MESSAGE',
  CLEAR_OTP_DATA: 'CLEAR_OTP_DATA',

  RESEND_LOGIN_OTP: 'RESEND_LOGIN_OTP',
  RESENDING_LOGIN_OTP: 'RESENDING_LOGIN_OTP',
  RESEND_LOGIN_OTP_FAILED: 'RESEND_LOGIN_OTP_FAILED',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload: payload,
  }),
  signup: (payload) => ({
    type: actions.SIGNUP,
    loading: true,
    payload: payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
  setSubmodules: (payload) => ({
    type: actions.SUBMODULES,
    modules: payload,
  }),
  forgotPassword: (email) => ({
    type: actions.FORGOT_PASSWORD,
    email,
  }),
  generateLoginOtp: (payload) => ({
    type: actions.REQUEST_LOGIN_OTP,
    payload: payload,
  }),
  loginData: (payload) => ({
    type: actions.LOGIN_DATA,
    payload: payload,
  }),
  setIsAgreeOtp: (payload) => ({
    type: actions.SAVE_AGREE_OTP,
    payload
  }),
  setShowOtpModal: (payload) => ({
    type: actions.SET_SHOW_OTP_MODAL,
    payload: payload,
  }),
  validateOtp: (payload) => ({
    type: actions.VALIDATE_OTP,
    payload: payload,
  }),
  clearOtpMessage: () => ({
    type: actions.CLEAR_OTP_MESSAGE,
  }),
  resendOtp: (payload) => ({
    type: actions.RESEND_LOGIN_OTP,
    payload,
  }),
  otpAttemps: (payload) => ({
    type: actions.RESEND_LOGIN_OTP,
    payload,
  }),
  clearOtpData: (payload) => ({
    type: actions.CLEAR_OTP_DATA,
    payload,
  }),
};
export default actions;
