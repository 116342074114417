import actions from './actions';

const initState = {
  // COMPANY
  fetchingCompany: false,
  company: null,
  fetchingCompanyFailed: null,
  // ROLES
  fetchingRoles: false,
  roles: null,
  fetchingRolesFailed: null,
  searchingCompany:false,
  // searchCompanyFailedMessage:null,
  searchCompanyFailed:false,
  // AUDIT LOGS
  searchingLogs: false,
  searchResult: null,
  searchCount: null,
  searchingIntegInfo: false,
  searchingIntegInfoFailed:null,
  loadingMoreLogs: false,
  logsPagination: null,
  //

  // LOOKUPS
  lookupsData: null,
  fetchingLookupsData: null,
  //
  addingLookups: false,
  lookupsAdded: false,
  //
  deletingLookups: false,
  lookupsDeleted: false,
  //
  updatingLookups: false,
  lookupsUpdated: false,


  // RESPONSE CODE
  fetchingResponseCode: false,
  responseCodes: null,
  loadingMoreResponseCode: false,
  responseCodePagination: null,
  //
  addingResponseCode: false,
  addingResponseCodeSuccess: false,
  addingResponseCodeFailed: false,
  addingResponseCodeFailedMessage: null,
  addingResponseCodeError: false,
  //
  updatingResponseCode: false,
  updatingResponseCodeSuccess: false,
  updatingResponseCodeFailed: false,
  updatingResponseCodeFailedMessage: null,
  updatingResponseCodeError: false,

  //
  deletingResponseCode: false,
  responseCodesDeleted: false,

  // USERS
  fetchingUsers: false,
  users: null,
  loadingMoreUsers: false,
  usersPagination: null,
  selectedUser: null,

  // ADD USERS
  addingUser: false,
  addingUserSuccess: null,
  addingUserFailed: null,
  addingUserError: null,
  // UPDATE USERS
  updatingUser: false,
  updatingUserSuccess: null,
  updatingUserFailed: null,
  updatingUserError: null,
  // DELETE USERS
  deletingUser: false,
  deletingUserSuccess: null,
  deletingUserFailed: null,
  deletingUserError: null,
  // RESET PASSWORD USERS
  resettingPasswordUser: false,
  resettingPasswordUserSuccess: null,
  resettingPasswordUserFailed: null,
  resettingPasswordUserError: null,


  // COMPANIES
  fetchingCompanies: false,
  companies: null,
  loadingMoreCompanies: false,
  companiesPagination: null,
  selectedCompany: null,

  // ADD COMPANY
  addingCompany: false,
  addingCompanySuccess: null,
  addingCompanyFailed: false,
  addingCompanyFailedMessage: null,
  addingCompanyError: null,
  // UPDATE COMPANY
  updatingCompany: false,
  updatingCompanySuccess: null,
  updatingCompanyFailed: false,
  updatingCompanyFailedMessage: null,
  updatingCompanyError: null,
  // DELETE COMPANY
  deletingCompany: false,
  deletingCompanySuccess: null,
  deletingCompanyFailed: null,
  deletingCompanyError: null,
  // DEACTIVATE COMPANY
  deactivatingCompany: false,
  deactivatingCompanySuccess: null,
  deactivatingCompanyFailed: null,
  deactivatingCompanyError: null,


  // INTEGRATOR
  fetchingIntegrators: false,
  integrators: null,
  loadingMoreIntegrators: false,
  integratorsPagination: null,
  selectedIntegrator: null,
  // INTEGRATION INFO
  fetchingIntegrationInfo: false,
  integrationInfo: null,
  loadingMoreIntegrationInfo: false,
  integrationInfoPagination: null,
  // selectedIntegrator: null,
  // UPDATE INTEGRATION ROLE
  updatingIntegrationRoles: false,
  updatingIntegrationRolesSuccess: null,
  updatingIntegrationRolesFailed: null,
  updatingIntegrationRolesError: null,

  // FETCH DOCS USERS
  fetchingDocsUsers: false,
  docsUsers: null,
  loadingMoreDocsUsers: false,
  docsUsersPagination: null,
  selectedDocsUser: null,
  // FETCH DOCS ACCESS
  fetchingDocsAccess: false,
  docsAccess: null,
  loadingMoreDocsAccess: false,
  docsAccessPagination: null,
  selectedDocsAccess: null,
  // UPDATE INTEGRATION ROLE
  updatingDocsAccess: false,
  updatingDocsAccessSuccess: null,
  updatingDocsAccessFailed: null,
  updatingDocsAccessError: null,
  // GROUPS AND EMAILS
  fetchingGroups: false,
  groupData: null,
  groupPagination: null,
  selectedGroupData: null,
  loadingMoreGroups: false,
  fetchingEmails: false,
  emailData: null,
  addedGroupData: null,
  addingGroup: false,
  addGroupSuccess: null,
  addGroupFailed: null,
  lookupsStatusData: null,
  fetchingStatusData: false,
  editedGroupData: null,
  editingGroupData: false,
  editGroupSuccess: null,
  editGroupFailed: null,
  deletedGroupData: null,
  deletingGroupData: false,
  deleteGroupSuccess: null,
  deleteGroupFailed: null,
  allCompanyData: null,
  fetchingAllCompany: false,
  addedEmailData: null,
  addingEmail: false,
  addEmailSuccess: null,
  addEmailFailed: null,
  editedEmailData: null,
  editingEmailData: false,
  editEmailSuccess: null,
  editEmailFailed: null,
  deletedEmailData: null,
  deletingEmailData: false,
  deleteEmailSuccess: null,
  deleteEmailFailed: null,

  // FETCHING MODULE
  fetchingModules: false,
  fetchedModules: null,
  // POSTING AUTHORIZATION
  postingAuthorizationRoleSuccessMsg: null,
  postingAuthorizationRoleFailedMsg: null,
  postedAuthorizationRole: null,
  postingAuthorizationRole: false,
  // FETCHING PRIVILEGE
  fetchingAuthRolesPrivilege: false,
  fetchedAuthRolesPrivilege: null,
  // UPDATING AUTH PRIVILEGE
  updatingAuthorizationRoleSuccessMsg: null,
  updatingAuthorizationRoleFailedMsg: null,
  updatingAuthorizationRole: false,
  updatedAuthorizationRole: null,
  // POSTING PRIVILEGE
  postingAuthorizationRolePrivilegeSuccessMsg: null,
  postingAuthorizationRolePrivilegeFailedMsg: null,
  postingAuthorizationRolePrivilege: false,
  postedAuthorizationRolePrivilege: null,
  // FETCHING AUTH ROLE
  fetchingAuthRoles: false,
  fetchedAuthRoles: null,

  searchUserSuccess:false,
  // searchUserSuccessMessage:null,
  searchUserFailedMessage:null,
  searchUserFailed:false,
  searchingUser:false,
};

export default (state = initState, action) => {
  switch (action.type) {

    // GROUPS AND EMAILS START
    case actions.FETCHED_GROUPS:
      return {
        ...state,
        fetchingGroups: false,
        loadingMoreGroups: false,
        groupData: action.payload,
        groupPagination: action.pagination,
      };
    case actions.FETCHING_GROUPS:
      return {
        ...state,
        groupData: null,
        fetchingGroups: true,
      };
    case actions.LOADING_MORE_GROUPS:
      return {
        ...state,
        loadingMoreGroups: true,
      };
    case actions.FETCH_GROUPS_FAILED:
      return {
        ...state,
        fetchingGroups: false,
      };

    case actions.SELECTED_GROUP_DATA:
      console.log('selectedGroupReducer');
      return {
        ...state,
        // fetchingGroups: false,
        // loadingMoreGroups: false,
        selectedGroupData: action.selectedGroup,
      };

    case actions.FETCHED_EMAILS:
      return {
        ...state,
        fetchingEmails: false,
        emailData: action.payload,
      };
    case actions.FETCHING_EMAILS:
      return {
        ...state,
        // emailData: null,
        fetchingEmails: true,
      };
    case actions.FETCH_EMAILS_FAILED:
      return {
        ...state,
        fetchingEmails: false,
      };

    case actions.ADDED_GROUP:
      return {
        ...state,
        addingGroup: false,
        addedGroupData: action.payload,
        addGroupSuccess: action.result,
      };
    case actions.ADDING_GROUP:
      return {
        ...state,
        addingGroup: true,
      };
    case actions.ADD_GROUP_FAILED:
      return {
        ...state,
        addingGroup: false,
        addGroupFailed: action.payload,
      };
    case actions.RESET_GROUP_DATA:
      return {
        ...state,
        addedGroupData: null,
        addGroupSuccess: null,
        addGroupFailed: null,
        editGroupSuccess: null,
        editGroupFailed: null,
        deleteGroupFailed: null,
        deleteGroupSuccess: null,
      };

    case actions.FETCHED_LOOKUPS_STATUS:
      return {
        ...state,
        fetchingStatusData: false,
        lookupsStatusData: action.payload,
      };
    case actions.FETCHING_LOOKUPS_STATUS:
      return {
        ...state,
        fetchingStatusData: true,
      };
    case actions.FETCH_LOOKUPS_STATUS_FAILED:
      return {
        ...state,
        fetchingStatusData: false,
      };

    case actions.EDITED_GROUP_DATA:
      return {
        ...state,
        editingGroupData: false,
        editedGroupData: action.payload,
        editGroupSuccess: action.result,
      };
    case actions.EDITING_GROUP_DATA:
      return {
        ...state,
        editingGroupData: true,
      };
    case actions.EDIT_GROUP_DATA_FAILED:
      return {
        ...state,
        editingGroupData: false,
        editGroupFailed: action.payload,
      };

    case actions.DELETED_GROUP_DATA:
      return {
        ...state,
        deletingGroupData: false,
        deletedGroupData: action.payload,
        deleteGroupSuccess: action.result,
      };
    case actions.DELETING_GROUP_DATA:
      return {
        ...state,
        deletingGroupData: true,
      };
    case actions.DELETE_GROUP_DATA_FAILED:
      return {
        ...state,
        deletingGroupData: false,
        deleteGroupFailed: action.payload,
      };

    case actions.FETCHED_ALL_COMPANY:
      return {
        ...state,
        fetchingAllCompany: false,
        allCompanyData: action.payload,
      };
    case actions.FETCHING_ALL_COMPANY:
      return {
        ...state,
        fetchingAllCompany: true,
      };
    case actions.FETCH_ALL_COMPANY_FAILED:
      return {
        ...state,
        fetchingAllCompany: false,
      };

    case actions.ADDED_EMAIL:
      return {
        ...state,
        addingEmail: false,
        addedEmailData: action.payload,
        addEmailSuccess: action.result,
      };
    case actions.ADDING_EMAIL:
      return {
        ...state,
        addingEmail: true,
      };
    case actions.ADD_EMAIL_FAILED:
      return {
        ...state,
        addingEmail: false,
        addEmailFailed: action.payload,
      };

    case actions.EDITED_EMAIL_DATA:
      return {
        ...state,
        editingEmailData: false,
        editedEmailData: action.payload,
        editEmailSuccess: action.result,
      };
    case actions.EDITING_EMAIL_DATA:
      return {
        ...state,
        editingEmailData: true,
      };
    case actions.EDIT_EMAIL_DATA_FAILED:
      return {
        ...state,
        editingEmailData: false,
        editEmailFailed: action.payload,
      };

    case actions.DELETED_EMAIL_DATA:
      return {
        ...state,
        deletingEmailData: false,
        deletedEmailData: action.payload,
        deleteEmailSuccess: action.result,
      };
    case actions.DELETING_EMAIL_DATA:
      return {
        ...state,
        deletingEmailData: true,
      };
    case actions.DELETE_EMAIL_DATA_FAILED:
      return {
        ...state,
        deletingEmailData: false,
        deleteEmailFailed: action.payload,
      };

    case actions.RESET_EMAIL_DATA:
      return {
        ...state,
        addedEmailData: null,
        addEmailSuccess: null,
        addEmailFailed: null,
        editEmailSuccess: null,
        editEmailFailed: null,
        deleteEmailFailed: null,
        deleteEmailSuccess: null,
      };
    // GROUPS AND EMAILS END

    //
    case actions.FETCHING_COMPANY:
      return {
        ...state,
        fetchingCompany: true,
      };
    case actions.FETCHED_COMPANY:
      return {
        ...state,
        fetchingCompany: false,
        company: action.payload,
      };
    case actions.FETCH_COMPANY_FAILED:
      return {
        ...state,
        fetchingCompany: false,
        fetchingCompanyFailed: action.payload,
      };
    case actions.FETCH_COMPANY_ERROR:
      return {
        ...state,
        fetchingCompany: false,
      };
    //
    case actions.FETCHING_ROLES:
      return {
        ...state,
        fetchingRoles: true,
      };
    case actions.FETCHED_ROLES:
      return {
        ...state,
        fetchingRoles: false,
        roles: action.payload,
      };
    case actions.FETCH_ROLES_FAILED:
      return {
        ...state,
        fetchingRoles: false,
        fetchingRolesFailed: action.payload,
      };
    case actions.FETCH_ROLES_ERROR:
      return {
        ...state,
        fetchingRoles: false,
      };

    //
    case actions.SEARCHING_AUDITLOGS:
      return {
        ...state,
        searchingLogs: true,
      };
    case actions.LOADING_MORE_LOGS:
      return {
        ...state,
        loadingMoreLogs: true,
      };
    case actions.SEARCHED_AUDITLOGS:
      return {
        ...state,
        searchingLogs: false,
        loadingMoreLogs: false,
        searchResult: action.result,
        searchCount: action.count,
        logsPagination: action.pagination,
      };
    case actions.SEARCH_AUDITLOGS_FAILED:
      return {
        ...state,
        searchingLogs: false,
        searchResult: null,
        searchCount: 0,
      };
    //
    case actions.FETCHING_LOOKUPS:
      return {
        ...state,
        fetchingLookupsData: true,
      };
    case actions.FETCHED_LOOKUPS:
      return {
        ...state,
        lookupsData: action.result,
        fetchingLookupsData: false,
      };
    case actions.ADDING_LOOKUPS:
      return {
        ...state,
        addingLookups: true,
      };
    case actions.ADDED_LOOKUPS:
      return {
        ...state,
        lookupsData: action.result,
        addingLookups: false,
        lookupsAdded: true,
      };
    case actions.RESET_ADD_LOOKUPS:
      return {
        ...state,
        // lookupsData: null,
        addingLookups: false,
        lookupsAdded: false,
      };
    case actions.DELETING_LOOKUPS:
      return {
        ...state,
        deletingLookups: true,
      };
    case actions.DELETED_LOOKUPS:
      return {
        ...state,
        lookupsData: action.result,
        lookupsDeleted: true,
        deletingLookups: false,
      };
    case actions.RESET_DELETE_LOOKUPS:
      return {
        ...state,
        deletingLookups: false,
        // lookupsData: null,
        lookupsDeleted: false,
      };
    case actions.UPDATING_LOOKUPS:
      return {
        ...state,
        updatingLookups: true,
      };
    case actions.UPDATED_LOOKUPS:
      return {
        ...state,
        lookupsData: action.result,
        lookupsUpdated: true,
        updatingLookups: false,
      };
    case actions.RESET_UPDATE_LOOKUPS:
      return {
        ...state,
        updatingLookups: false,
        // lookupsData: null,
        lookupsUpdated: false,
      };
    case actions.FETCHING_RESPONSE_CODE:
      return {
        ...state,
        fetchingResponseCode: true,
      };
    case actions.LOADING_MORE_RESPONSE_CODE:
      return {
        ...state,
        loadingMoreResponseCode: true,
      };
    case actions.FETCHED_RESPONSE_CODE:
      return {
        ...state,
        fetchingResponseCode: false,
        loadingMoreResponseCode: false,
        responseCodes: action.result,
        // searchCount: action.count,
        responseCodePagination: action.pagination,
      };
    case actions.FETCH_RESPONSE_CODE_FAILED:
      return {
        ...state,
        fetchingResponseCode: false,
        responseCodes: null,
        // searchCount: 0,
      };

    case actions.ADDING_RESPONSE_CODE:
      return {
        ...state,
        addingResponseCode: true,
        addingResponseCodeFailedMessage: null,
      };
    case actions.ADDED_RESPONSE_CODE:
      return {
        ...state,
        addingResponseCode: false,
        addingResponseCodeSuccess: true,
        responseCodes: action.result,
        // searchCount: action.count,
        responseCodePagination: action.pagination,
        // searchCount: 0,
      };
    case actions.ADD_RESPONSE_CODE_FAILED:
      return {
        ...state,
        addingResponseCode: false,
        addingResponseCodeFailed: true,
        addingResponseCodeFailedMessage: action.payload,
      };
    case actions.ADD_RESPONSE_CODE_ERROR:
      return {
        ...state,
        addingResponseCode: false,
      };
    case actions.RESET_ADD_RESPONSE_CODE:
      return {
        ...state,
        addingResponseCodeSuccess: false,
        addingResponseCodeFailed: false,
        addingResponseCodeError: false,
      };
    case actions.RESET_ADD_RESPONSE_CODE_MESSAGE:
      return {
        ...state,
        addingResponseCodeFailedMessage: null,
      };
    //
    case actions.UPDATING_RESPONSE_CODE:
      return {
        ...state,
        updatingResponseCode: true,
        updatingResponseCodeFailedMessage: null,
      };
    case actions.UPDATED_RESPONSE_CODE:
      return {
        ...state,
        updatingResponseCode: false,
        updatingResponseCodeSuccess: true,
        responseCodes: action.result,
        // searchCount: action.count,
        responseCodePagination: action.pagination,
        // searchCount: 0,
      };
    case actions.UPDATE_RESPONSE_CODE_FAILED:
      return {
        ...state,
        updatingResponseCode: false,
        updatingResponseCodeFailed: true,
        updatingResponseCodeFailedMessage: action.payload,
      };
    case actions.UPDATE_RESPONSE_CODE_ERROR:
      return {
        ...state,
        updatingResponseCode: false,
      };
    case actions.RESET_UPDATE_RESPONSE_CODE:
      return {
        ...state,
        updatingResponseCodeSuccess: false,
        updatingResponseCodeFailed: false,
        updatingResponseCodeError: false,
      };
    case actions.RESET_UPDATE_RESPONSE_CODE_MESSAGE:
      return {
        ...state,
        updatingResponseCodeFailedMessage: null,
      };
    //
    case actions.DELETING_RESPONSE_CODE:
      return {
        ...state,
        deletingResponseCode: true,
      };
    case actions.DELETED_RESPONSE_CODE:
      return {
        ...state,
        deletingResponseCode: false,
        responseCodesDeleted: true,
        responseCodes: action.result,
        // searchCount: action.count,
        responseCodePagination: action.pagination,
        // searchCount: 0,
      };
    case actions.DELETE_RESPONSE_CODE_FAILED:
      return {
        ...state,
        deletingResponseCode: false,
      };
    case actions.DELETE_RESPONSE_CODE_ERROR:
      return {
        ...state,
        deletingResponseCode: false,
      };
    case actions.RESET_DELETE_RESPONSE_CODE:
      return {
        ...state,
        responseCodesDeleted: false,
      };

    case actions.FETCHING_USERS:
      return {
        ...state,
        fetchingUsers: true,
      };
    case actions.LOADING_MORE_USERS:
      return {
        ...state,
        loadingMoreUsers: true,
      };
    case actions.FETCHED_USERS:
      return {
        ...state,
        fetchingUsers: false,
        loadingMoreUsers: false,
        users: action.result,
        // searchCount: action.count,
        usersPagination: action.pagination,
      };
    case actions.FETCH_USERS_FAILED:
      return {
        ...state,
        fetchingUsers: false,
        users: null,
        // searchCount: 0,
      };
    case actions.SET_USER_DATA:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case actions.ADDING_USERS:
      return {
        ...state,
        addingUser: true,
      };
    case actions.ADDED_USERS:

      const usersListAdd = state.users;
      usersListAdd.push(action.result);
      usersListAdd.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
      return {
        ...state,
        addingUser: false,
        addingUserSuccess: true,
        users: [...usersListAdd],
      };
    case actions.ADD_USERS_FAILED:
      return {
        ...state,
        addingUser: false,
        addingUserFailed: action.payload,
      };
    case actions.ADD_USERS_ERROR:
      return {
        ...state,
        addingUser: false,
        addingUserError: action.payload,
      };
    case actions.ADD_USERS_RESET:
      return {
        ...state,
        addingUserSuccess: false,
        addingUserFailed: null,
        addingUserError: null,
      };

    case actions.UPDATING_USERS:
      return {
        ...state,
        updatingUser: true,
      };
    case actions.UPDATED_USERS:

      const userListUpdate = state.users;
      for (let index = 0; index < userListUpdate.length; index++) {
        const element = userListUpdate[index];
        if (element.Id === action.result.Id) {
          userListUpdate[index] = action.result;
        }
      }
      return {
        ...state,
        updatingUser: false,
        updatingUserSuccess: true,
        users: [...userListUpdate],
        selectedUser: action.result,
      };
    case actions.UPDATE_USERS_FAILED:
      return {
        ...state,
        updatingUser: false,
        updatingUserFailed: action.payload,
      };
    case actions.UPDATE_USERS_ERROR:
      return {
        ...state,
        updatingUser: false,
        updatingUserError: action.payload,
      };
    case actions.UPDATE_USERS_RESET:
      return {
        ...state,
        updatingUserSuccess: false,
        updatingUserFailed: null,
        updatingUserError: null,
      };

    case actions.DELETING_USERS:
      return {
        ...state,
        deletingUser: true,
      };
    case actions.DELETED_USERS:
      return {
        ...state,
        deletingUser: true,
        deletingUserSuccess: true,
      };
    case actions.DELETE_USERS_FAILED:
      return {
        ...state,
        deletingUser: true,
        deletingUserFailed: action.payload,
      };
    case actions.DELETE_USERS_ERROR:
      return {
        ...state,
        deletingUser: true,
        deletingUserError: action.payload,
      };
    case actions.DELETE_USERS_RESET:
      return {
        ...state,
        deletingUserSuccess: false,
        deletingUserFailed: null,
        deletingUserError: null,
      };

    case actions.RESETTING_PASSWORD_USERS:
      return {
        ...state,
        resettingPasswordUser: true,
      };
    case actions.RESET_PASSWORD_USERS_SUCCESS:
      return {
        ...state,
        resettingPasswordUser: false,
        resettingPasswordUserSuccess: true,
      };
    case actions.RESET_PASSWORD_USERS_FAILED:
      return {
        ...state,
        resettingPasswordUser: false,
        resettingPasswordUserFailed: action.payload,
      };
    case actions.RESET_PASSWORD_USERS_ERROR:
      return {
        ...state,
        resettingPasswordUser: false,
        resettingPasswordUserError: action.payload,
      };
    case actions.RESET_PASSWORD_USERS_RESET:
      return {
        ...state,
        resettingPasswordUserSuccess: false,
        resettingPasswordUserFailed: null,
        resettingPasswordUserError: null,
      };

    //
    case actions.FETCHING_COMPANIES:
      return {
        ...state,
        fetchingCompanies: true,
      };
    case actions.LOADING_MORE_COMPANIES:
      return {
        ...state,
        loadingMoreCompanies: true,
      };
    case actions.FETCHED_COMPANIES:
      return {
        ...state,
        fetchingCompanies: false,
        loadingMoreCompanies: false,
        companies: action.result,
        // searchCount: action.count,
        companiesPagination: action.pagination,
      };
    case actions.FETCH_COMPANIES_FAILED:
      return {
        ...state,
        fetchingCompanies: false,
        companies: null,
        // searchCount: 0,
      };
    case actions.SET_COMPANIES_DATA:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case actions.FETCHING_COMPANIES_SEARCH:
      return {
        ...state,
        searchingCompany:true,
        // fetchingCompanies: true,
      };
      case actions.FETCHED_COMPANIES_SEARCH:
        return {
          ...state,
          searchingCompany:false,
          // fetchingCompanies: false,
          loadingMoreCompanies: false,
          companies: action.result,
          // searchCount: action.count,
          companiesPagination: action.pagination,
        };
        case actions.FETCHING_COMPANIES_SEARCH_FAILED:
          return {
            ...state,
            // fetchingCompanies: false,
            searchingCompany:false,
            searchCompanyFailed:true
            // companies: null,
            // searchCount: 0,
          };
    case actions.ADDING_COMPANY:
      return {
        ...state,
        addingCompany: true,
        addingCompanyFailedMessage: null,
      };
    case actions.ADDED_COMPANY:
      const companyListAdd = state.companies;
      companyListAdd.push(action.result);
      companyListAdd.sort((a, b) => (a.companyName > b.companyName ? 1 : -1));
      return {
        ...state,
        addingCompany: false,
        addingCompanySuccess: true,
        companies: [...companyListAdd],
      };
    case actions.ADD_COMPANY_FAILED:
      return {
        ...state,
        addingCompany: false,
        addingCompanyFailedMessage: action.payload,
        addingCompanyFailed: true,
      };
    case actions.ADD_COMPANY_ERROR:
      return {
        ...state,
        addingCompany: false,
        addingCompanyError: action.payload,
      };
    case actions.ADD_COMPANY_RESET:
      return {
        ...state,
        addingCompanySuccess: false,
        addingCompanyFailed: false,
        addingCompanyError: null,
      };
    case actions.ADD_COMPANY_RESET_MESSAGE:
      return {
        ...state,
        addingCompanyFailedMessage: null,
      };

    case actions.UPDATING_COMPANY:
      return {
        ...state,
        updatingCompany: true,
        updatingCompanyFailedMessage: null,
      };
    case actions.UPDATED_COMPANY:
      const companyListUpdate = state.companies;
      for (let index = 0; index < companyListUpdate.length; index++) {
        const element = companyListUpdate[index];
        if (element.Id === action.result.Id) {
          companyListUpdate[index] = action.result;
        }
      }
      // Object.assign(state.companies, companyListUpdate);
      return {
        ...state,
        updatingCompany: false,
        updatingCompanySuccess: true,
        companies: companyListUpdate,
        selectedCompany: action.result
      };
    case actions.UPDATE_COMPANY_FAILED:
      return {
        ...state,
        updatingCompany: false,
        updatingCompanyFailed: true,
        updatingCompanyFailedMessage: action.payload,
      };
    case actions.UPDATE_COMPANY_ERROR:
      return {
        ...state,
        updatingCompany: false,
        updatingCompanyError: action.payload,
      };
    case actions.UPDATE_COMPANY_RESET:
      return {
        ...state,
        updatingCompanySuccess: false,
        updatingCompanyFailed: null,
        updatingCompanyError: null,
      };
    case actions.UPDATE_COMPANY_RESET_MESSAGE:
      return {
        ...state,
        updatingCompanyFailedMessage: null,
      };

    case actions.DEACTIVATING_COMPANY:
      return {
        ...state,
        deactivatingCompany: true,
      };
    case actions.DEACTIVATED_COMPANY:
      const companyListDeact = state.companies;
      for (let index = 0; index < companyListDeact.length; index++) {
        const element = companyListDeact[index];
        if (element.Id === action.result.Id) {
          companyListDeact[index] = action.result;
        }
      }
      // Object.assign(state.companies, companyListDeact);
      return {
        ...state,
        deactivatingCompany: false,
        deactivatingCompanySuccess: true,
        companies: companyListDeact,
        selectedCompany: action.result
      };
    case actions.DEACTIVATE_COMPANY_FAILED:
      return {
        ...state,
        deactivatingCompany: false,
        deactivatingCompanyFailed: action.payload,
      };
    case actions.DEACTIVATE_COMPANY_ERROR:
      return {
        ...state,
        deactivatingCompany: false,
        deactivatingCompanyError: action.payload,
      };
    case actions.DEACTIVATE_COMPANY_RESET:
      return {
        ...state,
        deactivatingCompanySuccess: false,
        deactivatingCompanyFailed: null,
        deactivatingCompanyError: null,
      };

    case actions.DELETING_COMPANY:
      return {
        ...state,
        deletingCompany: true,
      };
    case actions.DELETED_COMPANY:
      return {
        ...state,
        deletingCompany: false,
        deletingCompanySuccess: true,
      };
    case actions.DELETE_COMPANY_FAILED:
      return {
        ...state,
        deletingCompany: false,
        deletingCompanyFailed: action.payload,
      };
    case actions.DELETE_COMPANY_ERROR:
      return {
        ...state,
        deletingCompany: false,
        deletingCompanyError: action.payload,
      };
    case actions.DELETE_COMPANY_RESET:
      return {
        ...state,
        deletingCompanySuccess: false,
        deletingCompanyFailed: null,
        deletingCompanyError: null,
      };

    //
    case actions.FETCHING_INTEGRATORS:
      return {
        ...state,
        fetchingIntegrators: true,
      };
    case actions.LOADING_MORE_INTEGRATORS:
      return {
        ...state,
        loadingMoreIntegrators: true,
      };
    case actions.FETCHED_INTEGRATORS:
      return {
        ...state,
        fetchingIntegrators: false,
        loadingMoreIntegrators: false,
        integrators: action.result,
        // searchCount: action.count,
        integratorsPagination: action.pagination,
      };
    case actions.FETCH_INTEGRATORS_FAILED:
      return {
        ...state,
        fetchingIntegrators: false,
        integrators: null,
        // searchCount: 0,
      };
    case actions.SET_INTEGRATORS_DATA:
      return {
        ...state,
        selectedIntegrator: action.payload,
      };
    //
    case actions.FETCHING_INTEGRATION_INFO:
      return {
        ...state,
        fetchingIntegrationInfo: true,
      };
    case actions.LOADING_MORE_INTEGRATION_INFO:
      return {
        ...state,
        loadingMoreIntegrationInfo: true,
      };
    case actions.FETCHED_INTEGRATION_INFO:
      return {
        ...state,
        fetchingIntegrationInfo: false,
        loadingMoreIntegrationInfo: false,
        integrationInfo: action.result,
        // searchCount: action.count,
        integrationInfoPagination: action.pagination,
      };
    case actions.FETCH_INTEGRATION_INFO_FAILED:
      return {
        ...state,
        fetchingIntegrationInfo: false,
        integrationInfo: null,
        // searchCount: 0,
      };
    case actions.SET_INTEGRATION_INFO_DATA:
      return {
        ...state,
        selectedIntegrator: action.payload,
      };

    case actions.UPDATING_INTEGRATION_ROLES:
      return {
        ...state,
        updatingIntegrationRoles: true,
      };
    case actions.UPDATED_INTEGRATION_ROLES:
      return {
        ...state,
        updatingIntegrationRoles: false,
        updatingIntegrationRolesSuccess: true,
      };
    case actions.UPDATE_INTEGRATION_ROLES_FAILED:
      return {
        ...state,
        updatingIntegrationRoles: false,
        updatingIntegrationRolesFailed: action.payload,
      };
    case actions.UPDATE_INTEGRATION_ROLES_ERROR:
      return {
        ...state,
        updatingIntegrationRoles: false,
        updatingIntegrationRolesError: action.payload,
      };
    case actions.UPDATE_INTEGRATION_ROLES_RESET:
      return {
        ...state,
        updatingIntegrationRolesSuccess: false,
        updatingIntegrationRolesFailed: null,
        updatingIntegrationRolesError: null,
      };
    case actions.FETCHED_MODULES:
      return {
        ...state,
        fetchingModules: false,
        fetchedModules: action.payload,
      };
    case actions.FETCHING_MODULES:
      return {
        ...state,
        fetchingModules: true,
      };
    case actions.FETCH_MODULES:
      return {
        ...state,
        fetchingModules: false,
        fetchedModules: null,
      };
    case actions.POSTED_AUTHORIZATION_ROLE:
      return {
        ...state,
        postingAuthorizationRoleSuccessMsg: action.message,
        postingAuthorizationRoleFailedMsg: null,
        postingAuthorizationRole: false,
      };
    case actions.POSTING_AUTHORIZATION_ROLE:
      return {
        ...state,
        postingAuthorizationRole: true,
      };
    case actions.POST_AUTHORIZATION_ROLE:
      return {
        ...state,
        postingAuthorizationRoleSuccessMsg: null,
        postingAurhorizationRoleFailedMsg: action.message,
        postingAuthirzationRole: false,
      };
    case actions.RESET_POSTING_AUTHORIZATION_ROLE:
      return {
        ...state,
        postingAuthorizationRoleSuccessMsg: null,
        postingAurhorizationRoleFailedMsg: null,
        postingAuthirzationRole: false,
      };
    case actions.FETCHED_AUTHORIZATION_ROLE_PRIVILAGE:
      return {
        ...state,
        fetchingAuthRolesPrivilege: false,
        fetchedAuthRolesPrivilege: action.payload,
      };
    case actions.FETCHING_AUTHORIZATION_ROLE_PRIVILAGE:
      return {
        ...state,
        fetchingAuthRolesPrivilege: true,
      };
    case actions.FETCH_AUTHORIZATION_ROLE_PRIVILAGE:
      return {
        ...state,
        fetchingAuthRolesPrivilege: false,
        fetchedAuthRolesPrivilege: null,
      };
    case actions.UPDATED_AUTHORIZATION_ROLE:
      return {
        ...state,
        updatingAuthorizationRoleSuccessMsg: action.message,
        updatingAuthorizationRoleFailedMsg: null,
        updatingAuthorizationRole: false,
      };
    case actions.UPDATING_AUTHORIZATION_ROLE:
      return {
        ...state,
        updatingAuthorizationRole: true,
      };
    case actions.UPDATE_AUTHORIZATION_ROLE:
      return {
        ...state,
        updatingAuthorizationRoleSuccessMsg: null,
        updatingAurhorizationRoleFailedMsg: action.message,
        updatingAuthirzationRole: false,
      };
    case actions.RESET_UPDATING_AUTHORIZATION_ROLE:
      return {
        ...state,
        updatingAuthorizationRoleSuccessMsg: null,
        updatingAurhorizationRoleFailedMsg: null,
        updatingAuthirzationRole: false,
      };
    case actions.POSTED_AUTHORIZATION_ROLE_PRIVILEGE:
      return {
        ...state,
        postingAuthorizationRolePrivilegeSuccessMsg: action.message,
        postingAuthorizationRolePrivilegeFailedMsg: null,
        postingAuthorizationRolePrivilege: false,
      };
    case actions.POSTING_AUTHORIZATION_ROLE_PRIVILEGE:
      return {
        ...state,
        postingAuthorizationRolePrivilege: true,
      };
    case actions.POST_AUTHORIZATION_ROLE_PRIVILEGE:
      return {
        ...state,
        postingAuthorizationRolePrivilegeSuccessMsg: null,
        postingAurhorizationRolePrivilegeFailedMsg: action.message,
        postingAuthirzationRolePrivilege: false,
      };
    case actions.RESET_AUTHORIZATION_FIELDS:
      return {
        ...state,
        postingAuthorizationRolePrivilegeSuccessMsg: null,
        postingAurhorizationRolePrivilegeFailedMsg: null,
        postingAuthirzationRolePrivilege: false,
        updatingAuthorizationRoleSuccessMsg: null,
        updatingAuthorizationRoleFailedMsg: null,
        updatingAuthorizationRole: false,
        postingAuthorizationRoleSuccessMsg: null,
        postingAuthorizationRoleFailedMsg: null,
        postingAuthorizationRole: false,
      };
    case actions.FETCHED_AUTH_MANAGEMENT_ROLE:
      return {
        ...state,
        fetchingAuthRoles: false,
        fetchedAuthRoles: action.payload,
      };
    case actions.FETCHING_AUTH_MANAGEMENT_ROLE:
      return {
        ...state,
        fetchingAuthRoles: true,
      };
    case actions.FETCH_AUTH_MANAGEMENT_ROLE:
      return {
        ...state,
        fetchingAuthRoles: false,
        fetchedAuthRoles: null,
      };

    //
    case actions.FETCHING_DOCS_USERS:
      return {
        ...state,
        fetchingDocsUsers: true,
      };
    case actions.LOADING_MORE_DOCS_USERS:
      return {
        ...state,
        loadingMoreDocsUsers: true,
      };
    case actions.FETCHED_DOCS_USERS:
      return {
        ...state,
        fetchingDocsUsers: false,
        loadingMoreDocsUsers: false,
        docsUsers: action.result,
        // searchCount: action.count,
        docsUsersPagination: action.pagination,
      };
    case actions.FETCH_DOCS_USERS_FAILED:
      return {
        ...state,
        fetchingDocsUsers: false,
        docsUsers: null,
        // searchCount: 0,
      };
    case actions.SET_DOCS_USERS_DATA:
      return {
        ...state,
        selectedDocUser: action.payload,
      };
    // FETCHING_DOCS_ACCESS
    case actions.FETCHING_DOCS_ACCESS:
      return {
        ...state,
        fetchingDocsAccess: true,
        docsAccess: null,
      };
    case actions.LOADING_MORE_DOCS_ACCESS:
      return {
        ...state,
        loadingMoreDocsAccess: true,
      };
    case actions.FETCHED_DOCS_ACCESS:
      return {
        ...state,
        fetchingDocsAccess: false,
        loadingMoreDocsAccess: false,
        docsAccess: action.result,
        // searchCount: action.count,
        docsAccessPagination: action.pagination,
      };
    case actions.FETCH_DOCS_ACCESS_FAILED:
      return {
        ...state,
        fetchingDocsAccess: false,
        docsAccess: null,
        // searchCount: 0,
      };
    case actions.SET_DOCS_ACCESS_DATA:
      return {
        ...state,
        selectedDocAccess: action.payload,
      };

    case actions.UPDATING_DOCS_ACCESS:
      return {
        ...state,
        updatingDocsAccess: true,
      };
    case actions.UPDATED_DOCS_ACCESS:
      return {
        ...state,
        updatingDocsAccess: false,
        updatingDocsAccessSuccess: true,
      };
    case actions.UPDATE_DOCS_ACCESS_FAILED:
      return {
        ...state,
        updatingDocsAccess: false,
        updatingDocsAccessFailed: action.payload,
      };
    case actions.UPDATE_DOCS_ACCESS_ERROR:
      return {
        ...state,
        updatingDocsAccess: false,
        updatingDocsAccessError: action.payload,
      };
    case actions.UPDATE_DOCS_ACCESS_RESET:
      return {
        ...state,
        updatingDocsAccessSuccess: false,
        updatingDocsAccessFailed: null,
        updatingDocsAccessError: null,
      };
      case actions.SEARCHING_USER:
        return {
          ...state,
          searchingUser:true,
          // fetchingUsers: true,
        };
      case actions.SEARCHED_USER:
        return {
          ...state,
          searchUserSuccess:true,
          fetchingUsers: false,
          searchingUser:false,
          loadingMoreUsers: false,
          users: action.result,
          // searchCount: action.count,
          usersPagination: action.pagination,
        };
        case actions.RESET_SEARCHED_USER:
        return {
          ...state,
          searchUserSuccess:false,
          searchUserFailed:false,
          searchingUser:false,
          searchUserFailedMessage:null,
          searchingCompany:false,
          searchCompanyFailed:false,
        };
      case actions.SEARCHING_USER_FAILED:
        return {
          ...state,
          fetchingUsers: false,
          // users: null,
          searchingUser:false,
          searchUserFailedMessage:`Cannot find user`,
          searchUserFailed:true,
          // searchCount: 0,
        };
      case actions.SEARCH_SELECTED_USER:
        return {
          ...state,
          selectedUser: action.payload,
        };
        case actions.SEARCHING_INTEGRATION_INFO:
          return {
            ...state,
            searchingIntegInfo:true,
            fetchingIntegrationInfo: true,
          };
        case actions.SEARCHED_INTEGRATION_INFO:
          return {
            ...state,
            fetchingIntegrationInfo: false,
            loadingMoreIntegrationInfo: false,
            integrationInfo: action.result,
            searchingIntegInfo:false,
            // searchCount: action.count,
            integrationInfoPagination: action.pagination,
          };
        case actions.SEARCHING_INTEGRATION_INFO_FAILED:
          return {
            ...state,
            fetchingIntegrationInfo: false,
            integrationInfo: null,
            searchingIntegInfo:false,
            searchingIntegInfoFailed:'Cannot find Wallet / Company '
            // searchCount: 0,
          };
          case actions.RESET_INTEGRATORS_INFO_SEARCH:
          return {
            ...state,
            searchingIntegInfo:false,
            searchingIntegInfoFailed:null,
            // searchCount: 0,
          };
        case actions.SET_INTEGRATION_INFO_DATA_SEARCHED:
          // console.log('selectedIntegrator',action.payload);
          return {
            ...state,
            selectedIntegrator: action.payload,
          };
    default:
      return state;
  }
}
