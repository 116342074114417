const actions = {
  //FETCH COMPANY
  //used in select for adding new user
  FETCH_COMPANY: 'FETCH_COMPANY',
  FETCHING_COMPANY: 'FETCHING_COMPANY',
  FETCHED_COMPANY: 'FETCHED_COMPANY',
  FETCH_COMPANY_FAILED: 'FETCH_COMPANY_FAILED',
  FETCH_COMPANY_ERROR: 'FETCH_COMPANY_ERROR',
  //FETCH ROLES
  FETCH_ROLES: 'FETCH_ROLES',
  FETCHING_ROLES: 'FETCHING_ROLES',
  FETCHED_ROLES: 'FETCHED_ROLES',
  FETCH_ROLES_FAILED: 'FETCH_ROLES_FAILED',
  FETCH_ROLES_ERROR: 'FETCH_ROLES_ERROR',


  //SEARCH AUDITLOGS
  SEARCH_AUDITLOGS: 'SEARCH_AUDITLOGS',
  SEARCHING_AUDITLOGS: 'SEARCHING_AUDITLOGS',
  LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
  SEARCHED_AUDITLOGS: 'SEARCHED_AUDITLOGS',
  SEARCH_AUDITLOGS_FAILED: 'SEARCH_AUDITLOGS_FAILED',
  RESET_AUDITLOGS: 'RESET_AUDITLOGS',

  //FETCH LOOKUPS
  FETCH_LOOKUPS: 'FETCH_LOOKUPS',
  FETCHING_LOOKUPS: 'FETCHING_LOOKUPS',
  // LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
  FETCHED_LOOKUPS: 'FETCHED_LOOKUPS',
  FETCH_LOOKUPS_FAILED: 'FETCH_LOOKUPS_FAILED',
  FETCH_LOOKUPS_ERROR: 'FETCH_LOOKUPS_ERROR',
  RESET_LOOKUPS: 'RESET_LOOKUPS',

  //ADD LOOKUPS
  ADD_LOOKUPS: 'ADD_LOOKUPS',
  ADDING_LOOKUPS: 'ADDING_LOOKUPS',
  // LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
  ADDED_LOOKUPS: 'ADDED_LOOKUPS',
  ADD_LOOKUPS_FAILED: 'ADD_LOOKUPS_FAILED',
  ADD_LOOKUPS_ERROR: 'ADD_LOOKUPS_ERROR',
  RESET_ADD_LOOKUPS: 'RESET_ADD_LOOKUPS',

  //DELETE LOOKUPS
  DELETE_LOOKUPS: 'DELETE_LOOKUPS',
  DELETING_LOOKUPS: 'DELETING_LOOKUPS',
  // LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
  DELETED_LOOKUPS: 'DELETED_LOOKUPS',
  DELETE_LOOKUPS_FAILED: 'DELETE_LOOKUPS_FAILED',
  DELETE_LOOKUPS_ERROR: 'DELETE_LOOKUPS_ERROR',
  RESET_DELETE_LOOKUPS: 'RESET_DELETE_LOOKUPS',

  //UPDATE LOOKUPS
  UPDATE_LOOKUPS: 'UPDATE_LOOKUPS',
  UPDATING_LOOKUPS: 'UPDATING_LOOKUPS',
  // LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
  UPDATED_LOOKUPS: 'UPDATED_LOOKUPS',
  UPDATE_LOOKUPS_FAILED: 'UPDATE_LOOKUPS_FAILED',
  UPDATE_LOOKUPS_ERROR: 'UPDATE_LOOKUPS_ERROR',
  RESET_UPDATE_LOOKUPS: 'RESET_UPDATE_LOOKUPS',

  //FETCH RESPONSE CODES
  FETCH_RESPONSE_CODE: 'FETCH_RESPONSE_CODE',
  FETCHING_RESPONSE_CODE: 'FETCHING_RESPONSE_CODE',
  LOADING_MORE_RESPONSE_CODE: 'LOADING_MORE_RESPONSE_CODE',
  FETCHED_RESPONSE_CODE: 'FETCHED_RESPONSE_CODE',
  FETCH_RESPONSE_CODE_FAILED: 'FETCH_RESPONSE_CODE_FAILED',
  FETCH_RESPONSE_CODE_ERROR: 'FETCH_RESPONSE_CODE_ERROR',
  RESET_RESPONSE_CODE: 'RESET_RESPONSE_CODE',

  //ADD RESPONSE CODES
  ADD_RESPONSE_CODE: 'ADD_RESPONSE_CODE',
  ADDING_RESPONSE_CODE: 'ADDING_RESPONSE_CODE',
  // LOADING_MORE_RESPONSE_CODE: 'LOADING_MORE_RESPONSE_CODE',
  ADDED_RESPONSE_CODE: 'ADDED_RESPONSE_CODE',
  ADD_RESPONSE_CODE_FAILED: 'ADD_RESPONSE_CODE_FAILED',
  ADD_RESPONSE_CODE_ERROR: 'ADD_RESPONSE_CODE_ERROR',
  RESET_ADD_RESPONSE_CODE: 'RESET_ADD_RESPONSE_CODE',
  RESET_ADD_RESPONSE_CODE_MESSAGE: 'RESET_ADD_RESPONSE_CODE_MESSAGE',

  //UPDATE RESPONSE CODES
  UPDATE_RESPONSE_CODE: 'UPDATE_RESPONSE_CODE',
  UPDATING_RESPONSE_CODE: 'UPDATING_RESPONSE_CODE',
  // LOADING_MORE_RESPONSE_CODE: 'LOADING_MORE_RESPONSE_CODE',
  UPDATED_RESPONSE_CODE: 'UPDATED_RESPONSE_CODE',
  UPDATE_RESPONSE_CODE_FAILED: 'UPDATE_RESPONSE_CODE_FAILED',
  UPDATE_RESPONSE_CODE_ERROR: 'UPDATE_RESPONSE_CODE_ERROR',
  RESET_UPDATE_RESPONSE_CODE: 'RESET_UPDATE_RESPONSE_CODE',
  RESET_UPDATE_RESPONSE_CODE_MESSAGE: 'RESET_UPDATE_RESPONSE_CODE_MESSAGE',

  //DELETE RESPONSE CODES
  DELETE_RESPONSE_CODE: 'DELETE_RESPONSE_CODE',
  DELETING_RESPONSE_CODE: 'DELETING_RESPONSE_CODE',
  // LOADING_MORE_RESPONSE_CODE: 'LOADING_MORE_RESPONSE_CODE',
  DELETED_RESPONSE_CODE: 'DELETED_RESPONSE_CODE',
  DELETE_RESPONSE_CODE_FAILED: 'DELETE_RESPONSE_CODE_FAILED',
  DELETE_RESPONSE_CODE_ERROR: 'DELETE_RESPONSE_CODE_ERROR',
  RESET_DELETE_RESPONSE_CODE: 'RESET_DELETE_RESPONSE_CODE',

  //FETCH USERS
  FETCH_USERS: 'FETCH_USERS',
  FETCHING_USERS: 'FETCHING_USERS',
  LOADING_MORE_USERS: 'LOADING_MORE_USERS',
  FETCHED_USERS: 'FETCHED_USERS',
  FETCH_USERS_FAILED: 'FETCH_USERS_FAILED',
  FETCH_USERS_ERROR: 'FETCH_USERS_ERROR',
  RESET_USERS: 'RESET_USERS',
  SET_USER_DATA: 'SET_USER_DATA',

  //ADD USERS
  ADD_USERS: 'ADD_USERS',
  ADDING_USERS: 'ADDING_USERS',
  ADDED_USERS: 'ADDED_USERS',
  ADD_USERS_FAILED: 'ADD_USERS_FAILED',
  ADD_USERS_ERROR: 'ADD_USERS_ERROR',
  ADD_USERS_RESET: 'ADD_USERS_RESET',

  //UPDATE  USERS
  UPDATE_USERS: 'UPDATE_USERS',
  UPDATING_USERS: 'UPDATING_USERS',
  UPDATED_USERS: 'UPDATED_USERS',
  UPDATE_USERS_FAILED: 'UPDATE_USERS_FAILED',
  UPDATE_USERS_ERROR: 'UPDATE_USERS_ERROR',
  UPDATE_USERS_RESET: 'UPDATE_USERS_RESET',

  //DELETE USERS
  DELETE_USERS: 'DELETE_USERS',
  DELETING_USERS: 'DELETING_USERS',
  DELETED_USERS: 'DELETED_USERS',
  DELETE_USERS_FAILED: 'DELETE_USERS_FAILED',
  DELETE_USERS_ERROR: 'DELETE_USERS_ERROR',
  DELETE_USERS_RESET: 'DELETE_USERS_RESET',

  //RESET PASSWORD USERS
  RESET_PASSWORD_USERS: 'RESET_PASSWORD_USERS',
  RESETTING_PASSWORD_USERS: 'RESETTING_PASSWORD_USERS',
  RESET_PASSWORD_USERS_SUCCESS: 'RESET_PASSWORD_USERS_SUCCESS',
  RESET_PASSWORD_USERS_FAILED: 'RESET_PASSWORD_USERS_FAILED',
  RESET_PASSWORD_USERS_ERROR: 'RESET_PASSWORD_USERS_ERROR',
  RESET_PASSWORD_USERS_RESET: 'RESET_PASSWORD_USERS_RESET',

  //FETCH COMPANY
  FETCH_COMPANIES: 'FETCH_COMPANIES',
  FETCHING_COMPANIES: 'FETCHING_COMPANIES',
  LOADING_MORE_COMPANIES: 'LOADING_MORE_COMPANIES',
  FETCHED_COMPANIES: 'FETCHED_COMPANIES',
  FETCH_COMPANIES_FAILED: 'FETCH_COMPANIES_FAILED',
  FETCH_COMPANIES_ERROR: 'FETCH_COMPANIES_ERROR',
  RESET_COMPANIES: 'RESET_COMPANIES',
  SET_COMPANIES_DATA: 'SET_COMPANIES_DATA',

  //FETCH COMPANY BY SEARCH
  FETCH_COMPANIES_SEARCH:'FETCH_COMPANIES_SEARCH',
  FETCHING_COMPANIES_SEARCH:'FETCHING_COMPANIES_SEARCH',
  FETCHED_COMPANIES_SEARCH:'FETCHED_COMPANIES_SEARCH',
  FETCHING_COMPANIES_SEARCH_FAILED:'FETCHING_COMPANIES_SEARCH_FAILED',

  //ADD COMPANY
  ADD_COMPANY: 'ADD_COMPANY',
  ADDING_COMPANY: 'ADDING_COMPANY',
  ADDED_COMPANY: 'ADDED_COMPANY',
  ADD_COMPANY_FAILED: 'ADD_COMPANY_FAILED',
  ADD_COMPANY_ERROR: 'ADD_COMPANY_ERROR',
  ADD_COMPANY_RESET: 'ADD_COMPANY_RESET',
  ADD_COMPANY_RESET_MESSAGE: 'ADD_COMPANY_RESET_MESSAGE',

  //UPDATE  COMPANY
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  UPDATING_COMPANY: 'UPDATING_COMPANY',
  UPDATED_COMPANY: 'UPDATED_COMPANY',
  UPDATE_COMPANY_FAILED: 'UPDATE_COMPANY_FAILED',
  UPDATE_COMPANY_ERROR: 'UPDATE_COMPANY_ERROR',
  UPDATE_COMPANY_RESET: 'UPDATE_COMPANY_RESET',
  UPDATE_COMPANY_RESET_MESSAGE: 'UPDATE_COMPANY_RESET_MESSAGE',

  //DELETE COMPANY
  DELETE_COMPANY: 'DELETE_COMPANY',
  DELETING_COMPANY: 'DELETING_COMPANY',
  DELETED_COMPANY: 'DELETED_COMPANY',
  DELETE_COMPANY_FAILED: 'DELETE_COMPANY_FAILED',
  DELETE_COMPANY_ERROR: 'DELETE_COMPANY_ERROR',
  DELETE_COMPANY_RESET: 'DELETE_COMPANY_RESET',

  //DEACTIVATE COMPANY
  DEACTIVATE_COMPANY: 'DEACTIVATE_COMPANY',
  DEACTIVATING_COMPANY: 'DEACTIVATING_COMPANY',
  DEACTIVATED_COMPANY: 'DEACTIVATED_COMPANY',
  DEACTIVATE_COMPANY_FAILED: 'DEACTIVATE_COMPANY_FAILED',
  DEACTIVATE_COMPANY_ERROR: 'DEACTIVATE_COMPANY_ERROR',
  DEACTIVATE_COMPANY_RESET: 'DEACTIVATE_COMPANY_RESET',

  //FETCH INTEGRATORS
  FETCH_INTEGRATORS: 'FETCH_INTEGRATORS',
  FETCHING_INTEGRATORS: 'FETCHING_INTEGRATORS',
  LOADING_MORE_INTEGRATORS: 'LOADING_MORE_INTEGRATORS',
  FETCHED_INTEGRATORS: 'FETCHED_INTEGRATORS',
  FETCH_INTEGRATORS_FAILED: 'FETCH_INTEGRATORS_FAILED',
  FETCH_INTEGRATORS_ERROR: 'FETCH_INTEGRATORS_ERROR',
  RESET_INTEGRATORS: 'RESET_INTEGRATORS',
  SET_INTEGRATORS_DATA: 'SET_INTEGRATORS_DATA',
  
  //FETCH INTEGRATORS USERNAME
  SEARCH_INTEGRATION_INFO: 'SEARCH_INTEGRATION_INFO',
  SEARCHING_INTEGRATION_INFO: 'SEARCHING_INTEGRATION_INFO',
  SEARCHED_INTEGRATION_INFO: 'SEARCHED_INTEGRATION_INFO',
  SEARCHING_INTEGRATION_INFO_FAILED: 'SEARCHING_INTEGRATION_INFO_FAILED',
  RESET_INTEGRATORS_INFO_SEARCH: 'RESET_INTEGRATORS_INFO_SEARCH',
  SET_INTEGRATION_INFO_DATA_SEARCHED: 'SET_INTEGRATION_INFO_DATA_SEARCHED',

  //FETCH  INTEGRATION INFO
  FETCH_INTEGRATION_INFO: 'FETCH_INTEGRATION_INFO',
  FETCHING_INTEGRATION_INFO: 'FETCHING_INTEGRATION_INFO',
  LOADING_MORE_INTEGRATION_INFO: 'LOADING_MORE_INTEGRATION_INFO',
  FETCHED_INTEGRATION_INFO: 'FETCHED_INTEGRATION_INFO',
  FETCH_INTEGRATION_INFO_FAILED: 'FETCH_INTEGRATION_INFO_FAILED',
  FETCH_INTEGRATION_INFO_ERROR: 'FETCH_INTEGRATION_INFO_ERROR',
  RESET_INTEGRATION_INFO: 'RESET_INTEGRATION_INFO',
  SET_INTEGRATION_INFO_DATA: 'SET_INTEGRATION_INFO_DATA',

  //UPDATE UPDATE_INTEGRATION_ROLES
  UPDATE_INTEGRATION_ROLES: 'UPDATE_INTEGRATION_ROLES',
  UPDATING_INTEGRATION_ROLES: 'UPDATING_INTEGRATION_ROLES',
  UPDATED_INTEGRATION_ROLES: 'UPDATED_INTEGRATION_ROLES',
  UPDATE_INTEGRATION_ROLES_FAILED: 'UPDATE_INTEGRATION_ROLES_FAILED',
  UPDATE_INTEGRATION_ROLES_ERROR: 'UPDATE_INTEGRATION_ROLES_ERROR',
  UPDATE_INTEGRATION_ROLES_RESET: 'UPDATE_INTEGRATION_ROLES_RESET',

  //FETCH DOCS USERS
  FETCH_DOCS_USERS: 'FETCH_DOCS_USERS',
  FETCHING_DOCS_USERS: 'FETCHING_DOCS_USERS',
  LOADING_MORE_DOCS_USERS: 'LOADING_MORE_DOCS_USERS',
  FETCHED_DOCS_USERS: 'FETCHED_DOCS_USERS',
  FETCH_DOCS_USERS_FAILED: 'FETCH_DOCS_USERS_FAILED',
  FETCH_DOCS_USERS_ERROR: 'FETCH_DOCS_USERS_ERROR',
  RESET_DOCS_USERS: 'RESET_DOCS_USERS',
  SET_DOCS_USERS_DATA: 'SET_DOCS_USERS_DATA',
  //FETCH DOCS ACCESS
  FETCH_DOCS_ACCESS: 'FETCH_DOCS_ACCESS',
  FETCHING_DOCS_ACCESS: 'FETCHING_DOCS_ACCESS',
  LOADING_MORE_DOCS_ACCESS: 'LOADING_MORE_DOCS_ACCESS',
  FETCHED_DOCS_ACCESS: 'FETCHED_DOCS_ACCESS',
  FETCH_DOCS_ACCESS_FAILED: 'FETCH_DOCS_ACCESS_FAILED',
  FETCH_DOCS_ACCESS_ERROR: 'FETCH_DOCS_ACCESS_ERROR',
  RESET_DOCS_ACCESS: 'RESET_DOCS_ACCESS',
  // SET_DOCS_ACCESS_DATA: 'SET_DOCS_ACCESS_DATA',

  //UPDATE UPDATE_INTEGRATION_ROLES
  UPDATE_DOCS_ACCESS: 'UPDATE_DOCS_ACCESS',
  UPDATING_DOCS_ACCESS: 'UPDATING_DOCS_ACCESS',
  UPDATED_DOCS_ACCESS: 'UPDATED_DOCS_ACCESS',
  UPDATE_DOCS_ACCESS_FAILED: 'UPDATE_DOCS_ACCESS_FAILED',
  UPDATE_DOCS_ACCESS_ERROR: 'UPDATE_DOCS_ACCESS_ERROR',
  UPDATE_DOCS_ACCESS_RESET: 'UPDATE_DOCS_ACCESS_RESET',
  

  // EMAILS AND GROUPS START
  FETCH_GROUPS: 'FETCH_GROUPS',
  FETCHING_GROUPS: 'FETCHING_GROUPS',
  FETCHED_GROUPS: 'FETCHED_GROUPS',
  FETCH_GROUPS_FAILED: 'FETCH_GROUPS_FAILED',
  SELECTED_GROUP_DATA: 'SELECTED_GROUP_DATA',
  LOADING_MORE_GROUPS: 'LOADING_MORE_GROUPS',

  FETCH_EMAILS: 'FETCH_EMAILS',
  FETCHING_EMAILS: 'FETCHING_EMAILS',
  FETCHED_EMAILS: 'FETCHED_EMAILS',
  FETCH_EMAILS_FAILED: 'FETCH_EMAILS_FAILED',

  ADD_GROUP: 'ADD_GROUP',
  ADDING_GROUP: 'ADDING_GROUP',
  ADDED_GROUP: 'ADDED_GROUP',
  ADD_GROUP_FAILED: 'ADD_GROUP_FAILED',
  RESET_GROUP_DATA: 'RESET_GROUP_DATA',

  FETCH_LOOKUPS_STATUS: 'FETCH_LOOKUPS_STATUS',
  FETCHING_LOOKUPS_STATUS: 'FETCHING_LOOKUPS_STATUS',
  FETCHED_LOOKUPS_STATUS: 'FETCHED_LOOKUPS_STATUS',
  FETCH_LOOKUPS_STATUS_FAILED: 'FETCH_LOOKUPS_STATUS_FAILED',

  EDIT_GROUP_DATA: 'EDIT_GROUP_DATA',
  EDITING_GROUP_DATA: 'EDITING_GROUP_DATA',
  EDITED_GROUP_DATA: 'EDITED_GROUP_DATA',
  EDIT_GROUP_DATA_FAILED: 'EDIT_GROUP_DATA_FAILED',

  DELETE_GROUP_DATA: 'DELETE_GROUP_DATA',
  DELETING_GROUP_DATA: 'DELETING_GROUP_DATA',
  DELETED_GROUP_DATA: 'DELETED_GROUP_DATA',
  DELETE_GROUP_DATA_FAILED: 'DELETE_GROUP_DATA_FAILED',

  FETCH_ALL_COMPANY: 'FETCH_ALL_COMPANY',
  FETCHING_ALL_COMPANY: 'FETCHING_ALL_COMPANY',
  FETCHED_ALL_COMPANY: 'FETCHED_ALL_COMPANY',
  FETCH_ALL_COMPANY_FAILED: 'FETCH_ALL_COMPANY_FAILED',

  ADD_EMAIL: 'ADD_EMAIL',
  ADDING_EMAIL: 'ADDING_EMAIL',
  ADDED_EMAIL: 'ADDED_EMAIL',
  ADD_EMAIL_FAILED: 'ADD_EMAIL_FAILED',
  RESET_EMAIL_DATA: 'RESET_EMAIL_DATA',

  EDIT_EMAIL_DATA: 'EDIT_EMAIL_DATA',
  EDITING_EMAIL_DATA: 'EDITING_EMAIL_DATA',
  EDITED_EMAIL_DATA: 'EDITED_EMAIL_DATA',
  EDIT_EMAIL_DATA_FAILED: 'EDIT_EMAIL_DATA_FAILED',

  DELETE_EMAIL_DATA: 'DELETE_EMAIL_DATA',
  DELETING_EMAIL_DATA: 'DELETING_EMAIL_DATA',
  DELETED_EMAIL_DATA: 'DELETED_EMAIL_DATA',
  DELETE_EMAIL_DATA_FAILED: 'DELETE_EMAIL_DATA_FAILED',

  // EMAILS AND GROUPS END

  FETCH_AUTH_MANAGEMENT_ROLE : 'FETCH_AUTH_MANAGEMENT_ROLE',
  FETCHING_AUTH_MANAGEMENT_ROLE:'FETCHING_AUTH_MANAGEMENT_ROLE',
  FETCHING_AUTH_MANAGEMENT_ROLE_FAILED:'FETCHING_AUTH_MANAGEMENT_ROLE_FAILED',
  FETCHED_AUTH_MANAGEMENT_ROLE:'FETCHED_AUTH_MANAGEMENT_ROLE',

  FETCH_MODULES : 'FETCH_MODULES',
  FETCHING_MODULES:'FETCHING_MODULESS',
  FETCHING_MODULES_FAILED:'FETCHING_MODULES_FAILED',
  FETCHED_MODULES:'FETCHED_MODULES',

  POST_AUTHORIZATION_ROLE : 'POST_AUTHORIZATION_ROLE',
  POSTING_AUTHORIZATION_ROLE:'POSTING_AUTHORIZATION_ROLE',
  POSTING_AUTHORIZATION_ROLE_FAILED:'POSTING_AUTHORIZATION_ROLE_FAILED',
  POSTED_AUTHORIZATION_ROLE:'POSTED_AUTHORIZATION_ROLE',
  RESET_POSTING_AUTHORIZATION_ROLE: 'RESET_POSTING_AUTHORIZATION_ROLE',

  FETCH_AUTHORIZATION_ROLE_PRIVILAGE : 'FETCH_AUTHORIZATION_ROLE_PRIVILAGE',
  FETCHING_AUTHORIZATION_ROLE_PRIVILAGE:'FETCHING_AUTHORIZATION_ROLE_PRIVILAGE',
  FETCHING_AUTHORIZATION_ROLE_PRIVILAGE_FAILED:'FETCHING_AUTHORIZATION_ROLE_PRIVILAGE_FAILED',
  FETCHED_AUTHORIZATION_ROLE_PRIVILAGE:'FETCHED_AUTHORIZATION_ROLE_PRIVILAGE',
  RESET_FETCHING_AUTHORIZATION_ROLE_PRIVILAGE: 'RESET_FETCHING_AUTHORIZATION_ROLE_PRIVILAGE',

  UPDATE_AUTHORIZATION_ROLE:'UPDATE_AUTHORIZATION_ROLE',
  UPDATING_AUTHORIZATION_ROLE:'UPDATING_AUTHORIZATION_ROLE',
  UPDATING_AUTHORIZATION_ROLE_FAILED:'UPDATING_AUTHORIZATION_ROLE_FAILED',
  UPDATED_AUTHORIZATION_ROLE:'UPDATED_AUTHORIZATION_ROLE',
  RESET_UPDATING_AUTHORIZATION_ROLE:'RESET_UPDATING_AUTHORIZATION_ROLE',

  POST_AUTHORIZATION_ROLE_PRIVILEGE : 'POST_AUTHORIZATION_ROLE_PRIVILEGE',
  POSTING_AUTHORIZATION_ROLE_PRIVILEGE:'POSTING_AUTHORIZATION_ROLE_PRIVILEGE',
  POSTING_AUTHORIZATION_ROLE_PRIVILEGE_FAILED:'POSTING_AUTHORIZATION_ROLE_PRIVILEGE_FAILED',
  POSTED_AUTHORIZATION_ROLE_PRIVILEGE:'POSTED_AUTHORIZATION_ROLE_PRIVILEGE',
  RESET_AUTHORIZATION_FIELDS: 'RESET_AUTHORIZATION_FIELDS',

  SEARCH_USER : 'SEARCH_USER',
  SEARCHING_USER : 'SEARCHING_USER',
  SEARCHED_USER : 'SEARCHED_USER',
  SEARCHING_USER_FAILED : 'SEARCHING_USER_FAILED',
  SEARCH_SELECTED_USER : 'SEARCH_SELECTED_USER',
  RESET_SEARCHED_USER: 'RESET_SEARCHED_USER',
  // EMAILS AND GROUPS START
  getGroups: (payload) => ({
    type: actions.FETCH_GROUPS,
    payload: payload,
  }),
  resetSearch: () => ({
    type: actions.RESET_SEARCHED_USER,
  }),
  searchUser: (payload) => ({
    type: actions.SEARCH_USER,
    payload: payload,
  }),
  getEmails: (payload) => ({
    type: actions.FETCH_EMAILS,
    payload: payload,
  }),
  setGroupData: (selectedGroup) => ({
    type: actions.SELECTED_GROUP_DATA,
    selectedGroup,
  }),
  addGroupData: (payload) => ({
    type: actions.ADD_GROUP,
    payload: payload,
  }),
  resetGroupData: () => ({
    type: actions.RESET_GROUP_DATA,
  }),
  getStatusLookups: (payload) => ({
    type: actions.FETCH_LOOKUPS_STATUS,
    payload: payload,
  }),
  editGroupData: (payload) => ({
    type: actions.EDIT_GROUP_DATA,
    payload: payload,
  }),
  deleteGroupData: (payload) => ({
    type: actions.DELETE_GROUP_DATA,
    payload: payload,
  }),
  getAllCompany: () => ({
    type: actions.FETCH_ALL_COMPANY,
    // payload: payload,
  }),
  addEmailData: (payload) => ({
    type: actions.ADD_EMAIL,
    payload: payload,
  }),
  editEmailData: (payload) => ({
    type: actions.EDIT_EMAIL_DATA,
    payload: payload,
  }),
  deleteEmailData: (payload) => ({
    type: actions.DELETE_EMAIL_DATA,
    payload: payload,
  }),
  resetEmailData: () => ({
    type: actions.RESET_EMAIL_DATA,
  }),
  // EMAILS AND GROUPS END
  
  fetchCompany: () => ({
    type: actions.FETCH_COMPANY,
  }),
  fetchRoles: () => ({
    type: actions.FETCH_ROLES,
  }),

  // AUDIT LOGS
  searchAuditLogs: (payload) => ({
    type: actions.SEARCH_AUDITLOGS,
    payload: payload,
  }),
  resetAuditLogs: () => ({
    type: actions.RESET_AUDITLOGS,
  }),
  // LOOKUPS
  fetchLookups: () => ({
    type: actions.FETCH_LOOKUPS,
  }),
  addLookups: (payload) => ({
    type: actions.ADD_LOOKUPS,
    payload
  }),
  resetAddLookups: () => ({
    type: actions.RESET_ADD_LOOKUPS,
  }),

  deleteLookups: (payload) => ({
    type: actions.DELETE_LOOKUPS,
    payload,
  }),
  resetDeleteLookups: () => ({
    type: actions.RESET_DELETE_LOOKUPS,
  }),

  updateLookups: (payload) => ({
    type: actions.UPDATE_LOOKUPS,
    payload,
  }),
  resetUpdateLookups: () => ({
    type: actions.RESET_UPDATE_LOOKUPS,
  }),
  // RESPONSE CODE
  fetchResponseCode: (payload) => ({
    type: actions.FETCH_RESPONSE_CODE,
    payload
  }),
  addResponseCode: (payload) => ({
    type: actions.ADD_RESPONSE_CODE,
    payload
  }),
  updateResponseCode: (payload) => ({
    type: actions.UPDATE_RESPONSE_CODE,
    payload
  }),
  deleteResponseCode: (payload) => ({
    type: actions.DELETE_RESPONSE_CODE,
    payload
  }),
  addResponseCodeReset: (payload) => ({
    type: actions.RESET_ADD_RESPONSE_CODE,
    payload
  }),
  addResponseCodeResetMessage: (payload) => ({
    type: actions.RESET_ADD_RESPONSE_CODE_MESSAGE,
    payload
  }),
  updateResponseCodeReset: (payload) => ({
    type: actions.RESET_UPDATE_RESPONSE_CODE,
    payload
  }),
  updateResponseCodeResetMessage: (payload) => ({
    type: actions.RESET_UPDATE_RESPONSE_CODE_MESSAGE,
    payload
  }),
  resetDeleteResponseCode: (payload) => ({
    type: actions.RESET_DELETE_RESPONSE_CODE,
    payload
  }),
  //
  fetchUsers: (payload) => ({
    type: actions.FETCH_USERS,
    payload
  }),
  setUsers: (payload) => ({
    type: actions.SET_USER_DATA,
    payload
  }),
  addUsers: (payload) => ({
    type: actions.ADD_USERS,
    payload
  }),
  addUsersReset: () => ({
    type: actions.ADD_USERS_RESET,
  }),
  updateUsers: (payload) => ({
    type: actions.UPDATE_USERS,
    payload
  }),
  updateUsersReset: () => ({
    type: actions.UPDATE_USERS_RESET,
  }),
  deleteUsers: (payload) => ({
    type: actions.DELETE_USERS,
    payload
  }),
  deleteUsersReset: () => ({
    type: actions.DELETE_USERS_RESET,
  }),
  resetPasswordUsers: (payload) => ({
    type: actions.RESET_PASSWORD_USERS,
    payload
  }),
  resetPasswordUsersReset: () => ({
    type: actions.RESET_PASSWORD_USERS_RESET,
  }),

  //
  fetchCompanies: (payload) => ({
    type: actions.FETCH_COMPANIES,
    payload,
  }),
  fetchCompaniesSearch: (payload) => ({
    type: actions.FETCH_COMPANIES_SEARCH,
    payload,
  }),
  setCompanies: (payload) => ({
    type: actions.SET_COMPANIES_DATA,
    payload
  }),
  addCompany: (payload) => ({
    type: actions.ADD_COMPANY,
    payload
  }),
  addCompanyReset: () => ({
    type: actions.ADD_COMPANY_RESET,
  }),
  addCompanyResetMessage: () => ({
    type: actions.ADD_COMPANY_RESET_MESSAGE,
  }),
  updateCompany: (payload) => ({
    type: actions.UPDATE_COMPANY,
    payload
  }),
  updateCompanyReset: () => ({
    type: actions.UPDATE_COMPANY_RESET,
  }),
  updateCompanyResetMessage: () => ({
    type: actions.UPDATE_COMPANY_RESET_MESSAGE,
  }),
  deactivateCompany: (payload) => ({
    type: actions.DEACTIVATE_COMPANY,
    payload
  }),
  deactivateCompanyReset: (payload) => ({
    type: actions.DEACTIVATE_COMPANY_RESET,
    payload
  }),
  deleteCompany: (payload) => ({
    type: actions.DELETE_COMPANY,
    payload
  }),
  deleteCompanyReset: (payload) => ({
    type: actions.DELETE_COMPANY_RESET,
    payload
  }),

  fetchIntegrators: (payload) => ({
    type: actions.FETCH_INTEGRATORS,
    payload
  }),
  searchIntegrators: (payload) => ({
    type: actions.SEARCH_INTEGRATION_INFO,
    payload
  }),
  setIntegrators: (payload) => ({
    type: actions.SET_INTEGRATORS_DATA,
    payload
  }),
  fetchIntegrationInfo: (payload) => ({
    type: actions.FETCH_INTEGRATION_INFO,
    payload
  }),
  resetSearchInfo: (payload) => ({
    type: actions.RESET_INTEGRATORS_INFO_SEARCH,
    payload
  }),
  updateIntegrationRole: (payload) => ({
    type: actions.UPDATE_INTEGRATION_ROLES,
    payload
  }),
  updateIntegrationRoleReset: (payload) => ({
    type: actions.UPDATE_INTEGRATION_ROLES_RESET,
    payload
  }),
  updateAuthorizationRole: (payload) => ({
    type: actions.UPDATE_AUTHORIZATION_ROLE,
    payload: payload,
  }),
  fetchAuthRoles: () => ({
    type: actions.FETCH_AUTH_MANAGEMENT_ROLE,
  }),
  fetchAuthRolesPrivilage: (payload) => ({
    type: actions.FETCH_AUTHORIZATION_ROLE_PRIVILAGE,
    payload: payload,
  }),
  fetchModules: () => ({
    type: actions.FETCH_MODULES,
  }),
  postAuthorizationRole:(payload) => ({
    type: actions.POST_AUTHORIZATION_ROLE,
    payload: payload,
  }),
  postAuthorizationRolePrivilege:(payload) => ({
    type: actions.POST_AUTHORIZATION_ROLE_PRIVILEGE,
    payload: payload,
  }),
  resetAuthorizationFields:(payload) => ({
    type: actions.RESET_AUTHORIZATION_FIELDS,
    payload: payload,
  }),
  

  fetchDocsUsers: (payload) => ({
    type: actions.FETCH_DOCS_USERS,
    payload
  }),
  setDocsUsers: (payload) => ({
    type: actions.SET_DOCS_USERS_DATA,
    payload
  }),
  fetchDocsAccess: (payload) => ({
    type: actions.FETCH_DOCS_ACCESS,
    payload
  }),
  updateDocsAccess: (payload) => ({
    type: actions.UPDATE_DOCS_ACCESS,
    payload
  }),
  updateDocsAccessReset: (payload) => ({
    type: actions.UPDATE_DOCS_ACCESS_RESET,
    payload
  }),

};
export default actions;
