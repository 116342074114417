import actions from './actions';

const initState = {
  authManagementRole: null,
  loadingAuthManagementRole: false,
  authManagementRoleSaved: false,
  rolePermission: null,
  payloadCore: null,
  selectedId: null,

  openRolePermissionDialog: false,
  statusData: null,
  addAuthRoleSuccess: false,

  // merchants
  fetchingMerchants: false,
  fetchedMerchants: null,

  fetchingTotalTransactions: false,
  fetchedTotalTransactions: null,

  fetchingAllTransactions: false,
  fetchedAllTransactions: null,

  //wallet details
  fetchedWalletDetails: null,
  fetchingWalletDetails: false,

  //with held transactions
  fetchedWithheldTransactions: null,
  fetchingWithheldTransactions: false,
  fetchingCorpWallets: false,
  fetchedCorpWallets: null,

  fetchingCorpWalletsByMid: false,
  fetchedCorpWalletsByMid: null,

  fetchingTransactionsCount: false,
  fetchedTransactionsCount: null,

  fetchingAllTransactionsCount: false,
  fetchedAllTransactionsCount: null,

  fetchingAllTransactionDetails: false,
  fetchedAllTransactionDetails: null,
  fetchingMoreTransactionDetails: null,
  fetchedAllTransactionDetailsPagination: null,

  updatingThreshold: false,
  updatedThreshold: null,

  fetchedLookupsCurrency: null,
  fetchingLookupsCurrency: false,

  fetchedWalletGroups: null,
  fetchingWalletGroups: false,

  addedCorpWallet: null,
  addingCorpWallet: false,
  addedWalletSuccessMsg: null,
  addedWalletFailedMsg: null,

  fetchedDailyBalance: null,
  fetchingDailyBalance: false,
  fetchingBankDetailsByTranxId: false,
  fetchedBankDetailsByTranxId: null,

  filteredTransactionBody: null,

  //post credit transaction
  postedCreditTransaction: null,
  postingCreditTransaction: false,
  postedCreditTransactionSuccess: null,
  postedCreditTransactionFailed: null,
  //post dedit transaction
  postedDebitTransaction: null,
  postingDebitTransaction: false,
  postedDebitTransactionSuccess: null,
  postedDebitTransactionFailed: null,
  //post Transfer transaction
  postedTransferTransaction: null,
  postingTransferTransaction: false,
  postedTransferTransactionSuccess: null,
  postedTransferTransactionFailed: null,
  //post settle transaction
  postedSettleTransaction: null,
  postedSettleTransactionSuccess: null,
  postedSettleTransactionFailed: null,
  postingSettleTransaction: false,
  //post cancel transaction
  postedCancelTransaction: null,
  postedCancelTransactionSuccess: null,
  postedCancelTransactionFailed: null,
  postingCancelTransaction: false,

  // TRX QUEUE
  fetchingTrxQueueMerchant: false,
  loadingMoreTrxQueueMerchant: false,
  trxQueueMerchantData: null,
  trxQueueMerchantOffset: null,
  trxQueueMerchantLimit: null,
  trxQueueMerchantMaxLength: null,
  trxQueueData: null,
  fetchingTrxQueue: false,
  trxQueueWalletIdData: null,
  fetchingTrxQueueWalletId: false,
  filteredTrxQueueData: null,
  filteringTrxQueue: false,

  // SEARCH TRANSACTIONS DETAILS
  fetchingSearchTransactionDetails: false,
  fetchedSearchTransactionDetails: null,
  fetchingMoreSearchedTransactionDetails: null,
  fetchedSearchTransactionDetailsPagination: null,

  // DOWNLOADS
  downloadedWithdrawalLoadReport: null,
  downloadingWithdrawalLoadReport: false,
  downloadedReconReport: null,
  downloadingReconReport: false,
  downloadList: null,
  fetchingDownloadList: false,
  previousDownloads: null,
  fetchingPreviousDownloads: false,
  downloadReportDates: null,

  // DOWNLOAD ADMIN
  downloadingAdminReport: false,
  downloadedAdminReport: null,
  downloadMessage: null,

  // FUND SOURCE
  fundSourceData: null,
  fetchingFundSource: false,
  loadingMoreFundSource: false,
  fundSourcePagination: null,
  bankAccountData: null,
  fetchingBankAccounts: false,
  addedFundSource: null,
  addingFundSource: false,
  fundSourceSuccess: null,
  fundSourceFailed: null,
  editedFundSource: null,
  editingFundSource: false,
  editFundSourceSuccess: null,
  editFundSourceFailed: null,
  deletedFundSource: null,
  deletingFundSource: false,
  deleteFundSourceSuccess: null,
  deleteFundSourceFailed: null,
  deactivatedFundSource: null,
  deactivatingFundSource: false,
  deactivateFundSourceSuccess: null,
  deactivateFundSourceFailed: null,


  // PREDICTIVE BALANCE
  predictiveBalanceData: null,
  fetchingPredictiveBalance: false,
  loadingMorePredictiveBalance: false,
  predictiveBalancePagination: null,
  pbBankAccountData: null,
  fetchingPbBankAccountData: false,
  pbPast90Days: null,
  fetchingPbPast90Days: false,

  changedWalletStatus: null,
  changedWalletStatusSuccess: null,
  changedWalletStatusFailed: null,
  changingWalletStatus: false,

  // FETCH BANK ACCOUNT BY ACCT NO
  fetchedBankAccountByAcctNo: null,
  fetchingBankAccountByAcctNo: false,

  addedBankAccount: null,
  addingBankAccount: false,
  bankAccountSuccess: null,
  bankAccountFailed: null,
  editedBankAccount: null,
  editingBankAccount: false,
  editBankAccountSuccess: null,
  editBankAccountFailed: null,
  deletedBankAccount: null,
  deletingBankAccount: false,
  deleteBankAccountSuccess: null,
  deleteBankAccountFailed: null,
  deactivatedBankAccount: null,
  deactivatingBankAccount: false,
  deactivateBankAccountSuccess: null,
  deactivateBankAccountFailed: null,

  fetchedLookupsBank: null,
  fetchingLookupsBank: false,

  // SEARCH MERCHANT TRANSACTION DETAILS
  fetchingSearchMerchantTransactionDetails: false,
  fetchedSearchMerchantTransactionDetails: null,
  fetchingMoreSearchedMerchantTransactionDetails: null,
  fetchedSearchMerchantTransactionDetailsPagination: null,

  //SEACH WALLET DETAILS
  searchingWalletDetails: false,
  searchedWalletDetails: null,

  fetchedIntegrationRoles: null,
  fetchingIntegrationRoles:false,
  fetchedIntegrationRolesPrivileges: null,
  fetchingIntegrationRolesPrivileges:false,

  updatedIntegrationRolesPrivileges: null,
  updatingIntegrationRolesPrivileges:false,
  updatedIntegrationRolesPrivilegesSuccess: null,
  updatedIntegrationRolesPrivilegesFailed: null,

  addedIntegrationRolesPrivileges: null,
  addedIntegrationRolesPrivilegesSuccess: null,
  addedIntegrationRolesPrivilegesFailed: null,
  addingIntegrationRolesPrivileges:false,

  fetchedIntegratorsAdminAccess:null,
  fetchingIntegratorsAdminAccess:false,
};

export default (state = initState, action) => {
  switch (action.type) {

    //FUND SOURCE
    case actions.FETCHED_FUND_SOURCE_GRP:
      return {
        ...state,
        fetchingFundSource: false,
        loadingMoreFundSource: false,
        fundSourceData: action.payload,
        fundSourcePagination: action.pagination,
      };
    case actions.FETCHING_FUND_SOURCE_GRP:
      return {
        ...state,
        fundSourceData: null,
        fundSourcePagination: null,
        fetchingFundSource: true,
      };
    case actions.LOADING_MORE_FUND_SOURCE_GRP:
      return {
        ...state,
        loadingMoreFundSource: true,
      };
    case actions.FETCH_FUND_SOURCE_GRP_FAILED:
      return {
        ...state,
        fetchingFundSource: false,
      };

    //PREDICTIVE BALANCE
    case actions.FETCHED_PREDICTIVE_BALANCE_GRP:
      return {
        ...state,
        fetchingPredictiveBalance: false,
        loadingMorePredictiveBalance: false,
        predictiveBalanceData: action.payload,
        predictiveBalancePagination: action.pagination,
      };
    case actions.FETCHING_PREDICTIVE_BALANCE_GRP:
      return {
        ...state,
        predictiveBalanceData: null,
        predictiveBalancePagination: null,
        fetchingPredictiveBalance: true,
      };
    case actions.LOADING_MORE_PREDICTIVE_BALANCE_GRP:
      return {
        ...state,
        loadingMorePredictiveBalance: true,
      };
    case actions.FETCH_PREDICTIVE_BALANCE_GRP_FAILED:
      return {
        ...state,
        fetchingPredictiveBalance: false,
      };
    case actions.FETCHED_PB_BA:
      return {
        ...state,
        pbBankAccountData: action.payload,
        fetchingPbBankAccountData: false,
      };
    case actions.FETCHING_PB_BA:
      return {
        ...state,
        fetchingPbBankAccountData: true,
      };
    case actions.FETCH_PB_BA_FAILED:
      return {
        ...state,
        fetchingPbBankAccountData: false,
        pbBankAccountData: action.payload,
      };
    case actions.FETCHED_PB_90DAYS:
      return {
        ...state,
        pbPast90Days: action.payload,
        fetchingPbPast90Days: false,
      };
    case actions.FETCHING_PB_90DAYS:
      return {
        ...state,
        fetchingPbPast90Days: true,
      };
    case actions.FETCH_PB_90DAYS_FAILED:
      return {
        ...state,
        fetchingPbPast90Days: false,
        pbPast90Days: action.payload,
      };
    case actions.FETCHED_BANK_ACCOUNTS:
      return {
        ...state,
        fetchingBankAccounts: false,
        bankAccountData: action.payload,
      };
    case actions.FETCHING_BANK_ACCOUNTS:
      return {
        ...state,
        fetchingBankAccounts: true,
      };
    case actions.FETCH_BANK_ACCOUNTS_FAILED:
      return {
        ...state,
        fetchingBankAccounts: false,
      };

    case actions.ADDED_FUND_SOURCE:
      return {
        ...state,
        addingFundSource: false,
        addedFundSource: action.payload,
        fundSourceSuccess: action.result,
      };
    case actions.ADDING_FUND_SOURCE:
      return {
        ...state,
        addingFundSource: true,
      };
    case actions.ADD_FUND_SOURCE_FAILED:
      return {
        ...state,
        addingFundSource: false,
        fundSourceFailed: action.payload,
      };

    case actions.EDITED_FUND_SOURCE:
      return {
        ...state,
        editingFundSource: false,
        editedFundSource: action.payload,
        editFundSourceSuccess: action.result,
      };
    case actions.EDITING_FUND_SOURCE:
      return {
        ...state,
        editingFundSource: true,
      };
    case actions.EDIT_FUND_SOURCE_FAILED:
      return {
        ...state,
        editingFundSource: false,
        editFundSourceFailed: action.payload,
      };

    case actions.DELETED_FUND_SOURCE:
      return {
        ...state,
        deletingFundSource: false,
        deletedFundSource: action.payload,
        deleteFundSourceSuccess: action.result,
      };
    case actions.DELETING_FUND_SOURCE:
      return {
        ...state,
        deletingFundSource: true,
      };
    case actions.DELETE_FUND_SOURCE_FAILED:
      return {
        ...state,
        deletingFundSource: false,
        deleteFundSourceFailed: action.payload,
      };

    case actions.DEACTIVATED_FUND_SOURCE:
      return {
        ...state,
        deactivatingFundSource: false,
        deactivatedFundSource: action.payload,
        deactivateFundSourceSuccess: action.result,
      };
    case actions.DEACTIVATING_FUND_SOURCE:
      return {
        ...state,
        deactivatingFundSource: true,
      };
    case actions.DEACTIVATE_FUND_SOURCE_FAILED:
      return {
        ...state,
        deactivatingFundSource: false,
        deactivateFundSourceFailed: action.payload,
      };

    // TRX QUEUE
    case actions.FETCHED_TRX_QUEUE_MERCHANT:
      return {
        ...state,
        fetchingTrxQueueMerchant: false,
        loadingMoreTrxQueueMerchant: false,
        trxQueueMerchantData: action.payload,
        trxQueueMerchantOffset: action.offset,
        trxQueueMerchantLimit: action.limit,
        trxQueueMerchantMaxLength: action.maxLength,
      };
    case actions.FETCHING_TRX_QUEUE_MERCHANT:
      return {
        ...state,
        trxQueueMerchantData: null,
        trxQueueMerchantOffset: null,
        trxQueueMerchantLimit: null,
        fetchingTrxQueueMerchant: true,
      };
    case actions.LOADING_MORE_TRX_QUEUE_MERCHANT:
      return {
        ...state,
        loadingMoreTrxQueueMerchant: true,
      };
    case actions.FETCH_TRX_QUEUE_MERCHANT_FAILED:
      return {
        ...state,
        fetchingTrxQueueMerchant: false,
      };

    case actions.FETCHED_TRX_QUEUE:
      return {
        ...state,
        fetchingTrxQueue: false,
        trxQueueData: action.payload,
      };
    case actions.FETCHING_TRX_QUEUE:
      return {
        ...state,
        fetchingTrxQueue: true,
      };
    case actions.FETCH_TRX_QUEUE_FAILED:
      return {
        ...state,
        fetchingTrxQueue: false,
      };

    case actions.FETCHED_TRX_QUEUE_WALLET_ID:
      return {
        ...state,
        fetchingTrxQueueWalletId: false,
        trxQueueWalletIdData: action.payload,
      };
    case actions.FETCHING_TRX_QUEUE_WALLET_ID:
      return {
        ...state,
        fetchingTrxQueueWalletId: true,
      };
    case actions.FETCH_TRX_QUEUE_WALLET_ID_FAILED:
      return {
        ...state,
        fetchingTrxQueueWalletId: false,
      };

    case actions.FILTERED_TRX_QUEUE:
      return {
        ...state,
        filteringTrxQueue: false,
        filteredTrxQueueData: action.trxQueueFilterData,
        trxQueueData: action.trxQueueFilterData,
      };
    case actions.FILTERING_TRX_QUEUE:
      return {
        ...state,
        filteringTrxQueue: true,
      };
    case actions.FILTER_TRX_QUEUE_FAILED:
      return {
        ...state,
        filteringTrxQueue: false,
      };

    // DOWNLOAD TRANSACTION REPORT
    case actions.DOWNLOADED_LOAD_WITHDRAW_REPORT:
      return {
        ...state,
        downloadingWithdrawalLoadReport: false,
        downloadedWithdrawalLoadReport: action.payload,
      };
    case actions.DOWNLOADING_LOAD_WITHDRAW_REPORT:
      return {
        ...state,
        downloadedWithdrawalLoadReport: null,
        downloadingWithdrawalLoadReport: true,
      };
    case actions.DOWNLOAD_LOAD_WITHDRAW_FAILED:
      return {
        ...state,
        downloadingWithdrawalLoadReport: false,
        downloadMessage: action.payload,
      };
    case actions.DOWNLOADED_RECONCILIATION_REPORT:
      return {
        ...state,
        downloadingReconReport: false,
        downloadedReconReport: action.payload,
      };
    case actions.DOWNLOADED_ADMIN_REPORT:
      return {
        ...state,
        downloadingAdminReport: false,
        downloadedAdminReport: action.payload,
      };
    case actions.DOWNLOADING_ADMIN_REPORT:
      return {
        ...state,
        downloadedAdminReport: null,
        downloadingAdminReport: true,
      };
    case actions.DOWNLOAD_ADMIN_REPORT_FAILED:
      return {
        ...state,
        downloadingAdminReport: false,
        downloadMessage: action.payload,
      };
    case actions.CLEAR_DOWNLOAD_MESSAGE:
      return {
        ...state,
        downloadingAdminReport: false,
        downloadMessage: null,
      };
    case actions.DOWNLOADING_RECONCILIATION_REPORT:
      return {
        ...state,
        downloadedReconReport: null,
        downloadingReconReport: true,
      };
    case actions.DOWNLOAD_RECONCILIATION_REPORT_FAILED:
      return {
        ...state,
        downloadingReconReport: false,
        downloadMessage: action.payload,
      };

    case actions.FETCHED_DOWNLOAD_LIST:
      return {
        ...state,
        fetchingDownloadList: false,
        downloadList: action.payload,
      };
    case actions.FETCHING_DOWNLOAD_LIST:
      return {
        ...state,
        fetchingDownloadList: true,
      };
    case actions.FETCH_DOWNLOAD_LIST_FAILED:
      return {
        ...state,
        fetchingDownloadList: false,
      };

    case actions.FETCHED_PREVIOUS_DOWNLOADS:
      return {
        ...state,
        fetchingPreviousDownloads: false,
        previousDownloads: action.payload,
      };
    case actions.FETCHING_PREVIOUS_DOWNLOADS:
      return {
        ...state,
        fetchingPreviousDownloads: true,
      };
    case actions.FETCH_PREVIOUS_DOWNLOADS_FAILED:
      return {
        ...state,
        fetchingPreviousDownloads: false,
      };

    case actions.SET_DOWNLOAD_REPORT_DATE:
      return {
        ...state,
        downloadReportDates: action.downloadReportDates,
      };

    // AUTH MANAGEMENT
    case actions.AUTH_MANAGEMENT_ROLE_FETCHED:
      return {
        ...state,
        authManagementRole: action.payload,
        loadingAuthManagementRole: false,
      };
    case actions.AUTH_MANAGEMENT_SAVED:
      return {
        ...state,
        authManagementRoleSaved: true,
      };
    case actions.RESET_SAVE_AUTH_MANAGEMENT:
      return {
        ...state,
        authManagementRoleSaved: false,
      };

    case actions.OPEN_ROLE_PERMISSION_DIALOG:
      return {
        ...state,
        openRolePermissionDialog: !state.openRolePermissionDialog,
      };

    case actions.ROLE_PERMISSION_FETCHED:
      return {
        ...state,
        rolePermission: action.payload,
        payloadCore: action.payloadCore,
        selectedId: action.selectedId,
      };

    case actions.RESET_ROLE_PERMISSION_DATA:
      return {
        ...state,
        rolePermission: null,
        selectedId: null,
      };

    case actions.STATUS_FETCHED:
      return {
        ...state,
        statusData: action.payload,
      };
    case actions.AUTH_ROLE_ADDED:
      return {
        ...state,
        addAuthRoleSuccess: true,
      };
    case actions.RESET_ADD_AUTH_ROLE:
      return {
        ...state,
        addAuthRoleSuccess: false,
      };
    // FETCHING TOTAL TRANSACTIONS
    case actions.FETCHED_TOTAL_TRANSACTIONS:
      return {
        ...state,
        fetchingTotalTransactions: false,
        fetchedTotalTransactions: action.payload,
      };
    case actions.FETCHING_TOTAL_TRANSACTIONS:
      return {
        ...state,
        fetchingTotalTransactions: true,
      };
    case actions.FETCH_TOTAL_TRANSACTIONS_FAILED:
      return {
        ...state,
        fetchingTotalTransactions: false,
      };
    // FETCHING ALL TRANSACTIONS
    case actions.FETCHED_ALL_TRANSACTIONS:
      return {
        ...state,
        fetchingAllTransactions: false,
        fetchedAllTransactions: action.payload,
      };
    case actions.FETCHING_ALL_TRANSACTIONS:
      return {
        ...state,
        fetchingAllTransactions: true,
      };
    case actions.FETCH_ALL_TRANSACTIONS_FAILED:
      return {
        ...state,
        fetchingAllTransactions: false,
      };
    //WITHHELD TRANSACTIONS
    case actions.FETCHED_WITHHELD_TRANSACTIONS:
      return {
        ...state,
        fetchingWithheldTransactions: false,
        fetchedWithheldTransactions: action.payload,
      };
    case actions.FETCHING_WITHHELD_TRANSACTIONS:
      return {
        ...state,
        fetchingWithheldTransactions: true,
      };
    case actions.FETCH_WITHHELD_TRANSACTIONS_FAILED:
      return {
        ...state,
        fetchingWithheldTransactions: false,
      };
    //WALLET DETAILS
    case actions.FETCHED_CORP_WALLETS_DETAILS:
      return {
        ...state,
        fetchingWalletDetails: false,
        fetchedWalletDetails: action.payload,
      };
    case actions.RESET_CORP_WALLETS_DETAILS:
      return {
        ...state,
        fetchedWalletDetails: null,
        fetchedWithheldTransactions: null,
        fetchedDailyBalance: null,
      };
    case actions.FETCHING_CORP_WALLETS_DETAILS:
      return {
        ...state,
        fetchingWalletDetails: true,
      };
    case actions.FETCH_CORP_WALLETS_DETAILS_FAILED:
      return {
        ...state,
        fetchingWalletDetails: false,
      };
    // FETCHING ALL MERCHANTS
    case actions.FETCHED_MERCHANTS:
      return {
        ...state,
        fetchingMerchants: false,
        fetchedMerchants: action.payload,
      };
    case actions.FETCHING_MERCHANTS:
      return {
        ...state,
        fetchingMerchants: true,
      };
    case actions.FETCH_MERCHANTS_FAILED:
      return {
        ...state,
        fetchingMerchants: false,
      }
    // FETCHING CORP WALLETS BY MID
    case actions.FETCHED_CORP_WALLETS_BY_MID:
      return {
        ...state,
        fetchingCorpWalletsByMid: false,
        fetchedCorpWalletsByMid: action.payload,
      };
    case actions.FETCHING_CORP_WALLETS_BY_MID:
      return {
        ...state,
        fetchingCorpWalletsByMid: true,
      };
    case actions.FETCH_CORP_WALLETS_BY_MID_FAILED:
      return {
        ...state,
        fetchingCorpWalletsByMid: false,
      };
    //

    // FETCHING TRANSACTIONS COUNT
    case actions.FETCHED_TRANSACTIONS_COUNT:
      return {
        ...state,
        fetchingTransactionsCount: false,
        fetchedTransactionsCount: action.payload,
      };
    case actions.FETCHING_TRANSACTIONS_COUNT:
      return {
        ...state,
        fetchingTransactionsCount: true,
      };
    case actions.FETCH_TRANSACTIONS_COUNT_FAILED:
      return {
        ...state,
        fetchingTransactionsCount: false,
      };
    //

    // FETCHING ALL TRANSACTIONS COUNT
    case actions.FETCHED_ALL_TRANSACTIONS_COUNT:
      return {
        ...state,
        fetchingAllTransactionsCount: false,
        fetchedAllTransactionsCount: action.payload,
      };
    case actions.FETCHING_ALL_TRANSACTIONS_COUNT:
      return {
        ...state,
        fetchingAllTransactionsCount: true,
      };
    case actions.FETCH_ALL_TRANSACTIONS_COUNT_FAILED:
      return {
        ...state,
        fetchingAllTransactionsCount: false,
      };
    //

    // FETCHING ALL TRANSACTION DETAILS
    case actions.FETCHED_ALL_TRANSACTION_DETAILS:
      return {
        ...state,
        fetchingAllTransactionDetails: false,
        fetchedAllTransactionDetails: action.payload,
        fetchedAllTransactionDetailsPagination: action.pagination,
        fetchingMoreTransactionDetails: false,
      };
    case actions.FETCHING_ALL_TRANSACTION_DETAILS:
      return {
        ...state,
        fetchingAllTransactionDetails: true,
      };
    case actions.FETCHING_MORE_TRANSACTION_DETAILS:
      return {
        ...state,
        fetchingMoreTransactionDetails: true,
      };
    case actions.FETCH_ALL_TRANSACTION_DETAILS_FAILED:
      return {
        ...state,
        fetchingAllTransactionDetails: false,
        fetchedAllTransactionDetailsPagination: null,
      };
    //

    // FETCHING BANK DETAILS BY TRANSACTION ID
    case actions.FETCHED_BANK_DETAILS_BY_TRANSACTION_ID:
      return {
        ...state,
        fetchingBankDetailsByTranxId: false,
        fetchedBankDetailsByTranxId: action.payload,
      };
    case actions.FETCHING_BANK_DETAILS_BY_TRANSACTION_ID:
      return {
        ...state,
        fetchingBankDetailsByTranxId: true,
      };
    case actions.FETCH_BANK_DETAILS_BY_TRANSACTION_ID_FAILED:
      return {
        ...state,
        fetchingBankDetailsByTranxId: false,
      };
    //

    // RESET
    case actions.RESET_DASHBOARD_DATA:
      return {
        ...state,
        fetchingTransactionsCount: false,
        fetchedTransactionsCount: null,

        fetchingAllTransactionsCount: false,
        fetchedAllTransactionsCount: null,

        fetchingCorpWalletsByMid: false,
        fetchedCorpWalletsByMid: null,

        fetchingAllTransactionDetails: false,
        fetchedAllTransactionDetails: null,
        fetchingMoreTransactionDetails: null,
        fetchedAllTransactionDetailsPagination: null,

        // fetchingSearchTransactionDetails: false,
        // fetchedSearchTransactionDetails: null,
        // fetchingMoreSearchedTransactionDetails: null,
        // fetchedSearchTransactionDetailsPagination: null,

        fetchingSearchMerchantTransactionDetails: false,
        fetchedSearchMerchantTransactionDetails: null,
        fetchingMoreSearchedMerchantTransactionDetails: null,
        fetchedSearchMerchantTransactionDetailsPagination: null,
      };

    // RESET WALLET DETAILS
    case actions.RESET_WALLET_DATA:
      return {
        ...state,
        fetchedWalletDetails: null,
        fetchingWalletDetails: false,
        fetchedWithheldTransactions: null,
        fetchingWithheldTransactions: false,
      };
    case actions.RESET_CORP_WALLETS:
      return {
        ...state,
        addingCorpWallet: false,
        addedWalletSuccessMsg: null,
        addedWalletFailedMsg: null,
        postedCreditTransaction: null,
        postedCreditTransactionSuccess: null,
        postedCreditTransactionFailed: null,
        postingCreditTransaction: false,
        postedTransferTransaction: null,
        postingTransferTransaction: false,
        postedTransferTransactionFailed: null,
        postedTransferTransactionSuccess: null,
        postedDebitTransaction: null,
        postingDebitTransaction: false,
        postedDebitTransactionSuccess: null,
        postedDebitTransactionFailed: null,
        postedSettleTransactionSuccess: null,
        postedSettleTransactionFailed: null,
        postingSettleTransaction: false,
        postedSettleTransaction: null,
        postedCancelTransaction: null,
        postedCancelTransactionSuccess: null,
        postedCancelTransactionFailed: null,
        postingCancelTransaction: false,
        changedWalletStatus: null,
        changedWalletStatusSuccess: null,
        changedWalletStatusFailed: null,
        changingWalletStatus: false,
        fetchedWithheldTransactions: null,
        fetchedDailyBalance: null,
      };

    case actions.RESET_WITHHELD_TRANSACTIONS_DATA:
      return {
        ...state,
        fetchedDailyBalance: null,
        fetchedWithheldTransactions: null,
        fetchingWithheldTransactions: false,
      };
    //UPDATE THRESHOLD
    case actions.UPDATED_THRESHOLD_AMOUNT:
      return {
        ...state,
        updatedThreshold: action.payload,
        updatingThreshold: false,
      };
    case actions.UPDATING_THRESHOLD_AMOUNT:
      return {
        ...state,
        updatingThreshold: true,
      };
    case actions.UPDATE_THRESHOLD_AMOUNT_FAILED:
      return {
        ...state,
        updatingThreshold: false,
      };
    //FETCH LOOKUPS CURRENCY
    case actions.FETCHED_LOOKUPS_CURRENCY:
      return {
        ...state,
        fetchedLookupsCurrency: action.payload,
        fetchingLookupsCurrency: false,
      };
    case actions.FETCHING_LOOKUPS_CURRENCY:
      return {
        ...state,
        fetchingLookupsCurrency: true,
      };
    case actions.FETCH_LOOKUPS_CURRENCY_FAILED:
      return {
        ...state,
        fetchingLookupsCurrency: false,
      };
    //FETCH WALLET GROUPS
    case actions.FETCHED_WALLET_GROUPS:
      return {
        ...state,
        fetchedWalletGroups: action.payload,
        fetchingWalletGroups: false,
      };
    case actions.FETCHING_WALLET_GROUPS:
      return {
        ...state,
        fetchingWalletGroups: true,
      };
    case actions.FETCH_WALLET_GROUPS_FAILED:
      return {
        ...state,
        fetchingWalletGroups: false,
      };
    //ADDED CORP WALLET
    case actions.ADDED_CORP_WALLET:
      console.log("REDUCER ADDED:", action.payload);
      return {
        ...state,
        addedCorpWallet: action.payload,
        addedWalletSuccessMsg: action.message,
        addedWalletFailedMsg: null,
        addingCorpWallet: false,
      };
    case actions.ADDING_CORP_WALLET:
      console.log("REDUCER ADDING:", action);
      return {
        ...state,
        addingCorpWallet: true,
      };
    case actions.ADD_CORP_WALLET_FAILED:
      return {
        ...state,
        addingCorpWallet: false,
        addedWalletFailedMsg: action.message,
      };
    // FETCH DAILY BALANCE
    case actions.FETCHED_DAILY_BALANCE_DETAILS:
      console.log("REDUCER ADDED:", action.payload);
      return {
        ...state,
        fetchedDailyBalance: action.payload,
        //addedMerchantDetailsMsg: action.message,
        fetchingDailyBalance: false,
      };
    case actions.FETCHING_DAILY_BALANCE_DETAILS:
      console.log("REDUCER ADDING:", action);
      return {
        ...state,
        fetchingDailyBalance: true,
      };
    case actions.FETCH_DAILY_BALANCE_DETAILS_FAILED:
      return {
        ...state,
        fetchingDailyBalance: false,
      }
    case actions.RESET_BANK_DETAILS_DATA:
      return {
        ...state,
        fetchingBankDetailsByTranxId: false,
        fetchedBankDetailsByTranxId: null,
      };
    case actions.FILTER_TRANSACTION_BODY:
      return {
        ...state,
        filteredTransactionBody: action.payload,
      }
    // FETCHING SEARCH TRANSACTION DETAILS
    case actions.FETCHED_SEARCH_TRANSACTION_DETAILS:
      return {
        ...state,
        fetchingSearchTransactionDetails: false,
        fetchedSearchTransactionDetails: action.payload,
        fetchedSearchTransactionDetailsPagination: action.pagination,
        fetchingMoreSearchedTransactionDetails: false,
      };
    case actions.FETCHING_SEARCH_TRANSACTION_DETAILS:
      return {
        ...state,
        fetchingSearchTransactionDetails: true,
      };
    case actions.FETCHING_MORE_SEARCHED_TRANSACTION_DETAILS:
      return {
        ...state,
        fetchingMoreSearchedTransactionDetails: true,
      };
    case actions.FETCH_SEARCH_TRANSACTION_DETAILS_FAILED:
      return {
        ...state,
        fetchingSearchTransactionDetails: false,
        fetchedSearchTransactionDetailsPagination: null,
      };
    //
    //POST CREDIT TRANSACTION

    case actions.POSTED_CREDIT_TRANSACTION:
      return {
        ...state,
        postedCreditTransaction: action.payload,
        postedCreditTransactionSuccess: action.message,
        postingCreditTransaction: false,
      };
    case actions.POSTING_CREDIT_TRANSACTION:
      return {
        ...state,
        postingCreditTransaction: true,
      };
    case actions.POST_CREDIT_TRANSACTION_FAILED:
      return {
        ...state,
        postingCreditTransaction: false,
        postedCreditTransactionFailed: action.message
      };
    //POST DEDIT TRANSACTION

    case actions.POSTED_DEBIT_TRANSACTION:
      return {
        ...state,
        postedDebitTransaction: action.payload,
        postedDebitTransactionSuccess: action.message,
        postedDebitTransactionFailed: null,
        postingDebitTransaction: false,
      };
    case actions.POSTING_DEBIT_TRANSACTION:
      return {
        ...state,
        postingDebitTransaction: true,
      };
    case actions.POST_DEBIT_TRANSACTION_FAILED:
      return {
        ...state,
        postingDebitTransaction: false,
        postedDebitTransactionFailed: action.message,
      };
    // POST TRANSFER TRANSACTION
    case actions.POSTED_TRANSFER_TRANSACTION:
      return {
        ...state,
        postedTransferTransaction: action.payload,
        postedTransferTransactionSuccess: action.message,
        postingTransferTransaction: false,
      };
    case actions.POSTING_TRANSFER_TRANSACTION:
      return {
        ...state,
        postingTransferTransaction: true,
      };
    case actions.POST_TRANSFER_TRANSACTION_FAILED:
      return {
        ...state,
        postingTransferTransaction: false,
        postedTransferTransactionFailed: action.payload,
      };
    // POST SETTlE TRANSACTION
    case actions.POSTED_SETTLE_TRANSACTION:
      return {
        ...state,
        postedSettleTransaction: action.payload,
        postedSettleTransactionSuccess: action.message,
        postingSettleTransaction: false,
      };
    case actions.POSTING_SETTLE_TRANSACTION:
      return {
        ...state,
        postingSettleTransaction: true,
      };
    case actions.POST_SETTLE_TRANSACTION_FAILED:
      return {
        ...state,
        postingSettleTransaction: false,
        postedSettleTransactionFailed: action.message,
      };
    // POST CANCEL TRANSACTION
    case actions.POSTED_CANCEL_TRANSACTION:
      return {
        ...state,
        postedCancelTransaction: action.payload,
        postedCancelTransactionSuccess: action.message,
        postingCancelTransaction: false,
      };
    case actions.POSTING_CANCEL_TRANSACTION:
      return {
        ...state,
        postingCancelTransaction: true,
      };
    case actions.POST_CANCEL_TRANSACTION_FAILED:
      return {
        ...state,
        postedCancelTransactionFailed: action.message,
        postingCancelTransaction: false,
      };

    // CHANGE WALLET STATUS
    case actions.CHANGED_WALLET_STATUS:
      return {
        ...state,
        changedWalletStatus: action.payload,
        changedWalletStatusSuccess: action.message,
        changingWalletStatus: false,
      };
    case actions.CHANGING_WALLET_STATUS:
      return {
        ...state,
        changingWalletStatus: true,
      };
    case actions.CHANGE_WALLET_STATUS_FAILED:
      return {
        ...state,
        changedWalletStatusFailed: action.message,
        changingWalletStatus: false,
      };
    // FETCH BANK ACCOUNT BY ACCT NO
    case actions.FETCHED_BANK_ACCOUNT_BY_ACCT_NO:
      return {
        ...state,
        fetchedBankAccountByAcctNo: action.payload,
        fetchingBankAccountByAcctNo: false,
      };
    case actions.FETCHING_BANK_ACCOUNT_BY_ACCT_NO:
      return {
        ...state,
        fetchingBankAccountByAcctNo: true,
      };
    case actions.FETCH_BANK_ACCOUNT_BY_ACCT_NO_FAILED:
      return {
        ...state,
        fetchingBankAccountByAcctNo: false,
      };

    // CRUD BANK ACCOUNT
    case actions.ADDED_BANK_ACCOUNT:
      return {
        ...state,
        addingBankAccount: false,
        addedBankAccount: action.payload,
        bankAccountSuccess: action.result,
      };
    case actions.ADDING_BANK_ACCOUNT:
      return {
        ...state,
        addingBankAccount: true,
      };
    case actions.ADD_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        addingBankAccount: false,
        bankAccountFailed: action.payload,
      };

    case actions.EDITED_BANK_ACCOUNT:
      return {
        ...state,
        editingBankAccount: false,
        editedBankAccount: action.payload,
        editBankAccountSuccess: action.result,
      };
    case actions.EDITING_BANK_ACCOUNT:
      return {
        ...state,
        editingBankAccount: true,
      };
    case actions.EDIT_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        editingBankAccount: false,
        editBankAccountFailed: action.payload,
      };

    case actions.DELETED_BANK_ACCOUNT:
      return {
        ...state,
        deletingBankAccount: false,
        deletedBankAccount: action.payload,
        deleteBankAccountSuccess: action.result,
      };
    case actions.DELETING_BANK_ACCOUNT:
      return {
        ...state,
        deletingBankAccount: true,
      };
    case actions.DELETE_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        deletingBankAccount: false,
        deleteBankAccountFailed: action.payload,
      };

    case actions.DEACTIVATED_BANK_ACCOUNT:
      return {
        ...state,
        deactivatingBankAccount: false,
        deactivatedBankAccount: action.payload,
        deactivateBankAccountSuccess: action.result,
      };
    case actions.DEACTIVATING_BANK_ACCOUNT:
      return {
        ...state,
        deactivatingBankAccount: true,
      };
    case actions.DEACTIVATE_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        deactivatingBankAccount: false,
        deactivateBankAccountFailed: action.payload,
      };
    //
    //FETCH LOOKUPS CURRENCY
    case actions.FETCHED_LOOKUPS_BANK:
      return {
        ...state,
        fetchedLookupsBank: action.payload,
        fetchingLookupsBank: false,
      };
    case actions.FETCHING_LOOKUPS_BANK:
      return {
        ...state,
        fetchingLookupsBank: true,
      };
    case actions.FETCH_LOOKUPS_BANK_FAILED:
      return {
        ...state,
        fetchingLookupsBank: false,
      };

    case actions.RESET_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccountData: null,
        fetchingBankAccounts: false,
      }
    case actions.RESET_EDIT_BANK_ACCOUNTS:
      return {
        ...state,
        editedBankAccount: null,
        editingBankAccount: false,
        editBankAccountSuccess: null,
        editBankAccountFailed: null,
      }
    case actions.RESET_DELETE_BANK_ACCOUNTS:
      return {
        ...state,
        deletedBankAccount: null,
        deletingBankAccount: false,
        deleteBankAccountSuccess: null,
        deleteBankAccountFailed: null,
      }
    case actions.RESET_DEACTIVATE_BANK_ACCOUNTS:
      return {
        ...state,
        deactivatedBankAccount: null,
        deactivatingBankAccount: false,
        deactivateBankAccountSuccess: null,
        deactivateBankAccountFailed: null,
      }
    // FETCHING SEARCH MERCHANT TRANSACTION DETAILS
    case actions.FETCHED_SEARCH_MERCHANT_TRANSACTION_DETAILS:
      return {
        ...state,
        fetchingSearchMerchantTransactionDetails: false,
        fetchedSearchMerchantTransactionDetails: action.payload,
        fetchedSearchMerchantTransactionDetailsPagination: action.pagination,
        fetchingMoreSearchedMerchantTransactionDetails: false,
      };
    case actions.FETCHING_SEARCH_MERCHANT_TRANSACTION_DETAILS:
      return {
        ...state,
        fetchingSearchMerchantTransactionDetails: true,
      };
    case actions.FETCHING_MORE_SEARCHED_MERCHANT_TRANSACTION_DETAILS:
      return {
        ...state,
        fetchingMoreSearchedMerchantTransactionDetails: true,
      };
    case actions.FETCH_SEARCH_MERCHANT_TRANSACTION_DETAILS_FAILED:
      return {
        ...state,
        fetchingSearchMerchantTransactionDetails: false,
        fetchedSearchMerchantTransactionDetailsPagination: null,
      };
    //
    // SEARCH WALLET DETAILS
    case actions.SEARCHED_WALLET_DETAILS:
      return {
        ...state,
        searchingWalletDetails: false,
        searchedWalletDetails: action.payload,
      };
    case actions.SEARCHING_WALLET_DETAILS:
      return {
        ...state,
        searchingWalletDetails: true,
      };
    case actions.SEARCH_WALLET_DETAILS_FAILED:
      return {
        ...state,
        searchingWalletDetails: false,
      };
      case actions.SEARCHED_WITHHELD_TRANSACTIONS:
        console.log('actionPayload',action.payload);
      return {
        ...state,
        fetchingWithheldTransactions: false,
        fetchedWithheldTransactions: action.payload,
      };
    case actions.SEARCHING_WITHHELD_TRANSACTIONS:
      return {
        ...state,
        fetchingWithheldTransactions: true,
      };
    case actions.SEARCH_WITHHELD_TRANSACTIONS_FAILED:
      return {
        ...state,
        fetchingWithheldTransactions: false,
      };
      case actions.FETCHED_INTEGRATION_ROLE:
      return {
        ...state,
        fetchedIntegrationRoles: action.payload,
        fetchingIntegrationRoles: false,
      };
    case actions.FETCHING_INTEGRATION_ROLE:
      return {
        ...state,
        fetchingIntegrationRoles: true,
      };
    case actions.FETCHING_INTEGRATION_ROLE_FAILED:
      return {
        ...state,
        fetchingIntegrationRoles: false,
      };
      case actions.FETCHED_INTEGRATION_ROLES_PRIVILEGES:
        return {
          ...state,
          fetchedIntegrationRolesPrivileges: action.payload,
          fetchingIntegrationRolesPrivileges: false,
        };
      case actions.FETCHING_INTEGRATION_ROLES_PRIVILEGES:
        return {
          ...state,
          fetchingIntegrationRolesPrivileges: true,
        };
      case actions.FETCHING_INTEGRATION_ROLES_PRIVILEGES_FAILED:
        return {
          ...state,
          fetchingIntegrationRolesPrivileges: false,
        };
        case actions.UPDATED_INTEGRATION_ROLES_PRIVILEGES:
        return {
          ...state,
          updatedIntegrationRolesPrivileges: action.payload,
          updatedIntegrationRolesPrivilegesSuccess:action.message,
          updatingIntegrationRolesPrivileges: false,
        };
      case actions.UPDATING_INTEGRATION_ROLES_PRIVILEGES:
        return {
          ...state,
          updatingIntegrationRolesPrivileges: true,
        };
      case actions.UPDATING_INTEGRATION_ROLES_PRIVILEGES_FAILED:
        return {
          ...state,
          updatingIntegrationRolesPrivileges: false,
          updatedIntegrationRolesPrivilegesFailed:action.message,
        };
        case actions.ADDED_INTEGRATION_ROLES_PRIVILEGE:
          return {
            ...state,
            addedIntegrationRolesPrivileges: action.payload,
            addedIntegrationRolesPrivilegesSuccess:action.message,
            addingIntegrationRolesPrivileges: false,
          };
        case actions.ADDING_INTEGRATION_ROLES_PRIVILEGE:
          return {
            ...state,
            addingIntegrationRolesPrivileges: true,
          };
        case actions.ADDING_INTEGRATION_ROLES_PRIVILEGE_FAILED:
          return {
            ...state,
            addingIntegrationRolesPrivileges: false,
            addedIntegrationRolesPrivilegesSuccessFailed:action.message,
          };
          case actions.RESET_ADD_INTEGRATION_ROLES_PRIVILEGE:
      return {
        ...state,
        addedIntegrationRolesPrivilegesSuccess: null,
        addedIntegrationRolesPrivilegesSuccessFailed:null,
        updatedIntegrationRolesPrivilegesSuccess:null,
        updatedIntegrationRolesPrivilegesFailed:null,
      };
          case actions.FETCHED_INTEGRATORS_ADMIN_ACCESS:
          return {
            ...state,
            fetchedIntegratorsAdminAccess: action.payload,
            fetchingIntegratorsAdminAccess: false,
          };
        case actions.FETCHING_INTEGRATORS_ADMIN_ACCESS:
          return {
            ...state,
            fetchingIntegratorsAdminAccess: true,
          };
        case actions.FETCHING_INTEGRATORS_ADMIN_ACCESS_FAILED:
          return {
            ...state,
            fetchingIntegratorsAdminAccess: false,
          };
    default:
      return state;
  }
}
