const actions = {
  // NEW RESET PASSWORD
  NEW_RESET_MERCHANT_USER_PASSWORD: 'NEW_RESET_MERCHANT_USER_PASSWORD',
  NEW_RESETTING_MERCHANT_USER_PASSWORD: 'NEW_RESETTING_MERCHANT_USER_PASSWORD',
  NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS: 'NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS',
  NEW_MERCHANT_USER_PASSWORD_RESET_FAILED: 'NEW_MERCHANT_USER_PASSWORD_RESET_FAILED',
  NEW_MERCHANT_USER_PASSWORD_RESET_ERROR: 'NEW_MERCHANT_USER_PASSWORD_RESET_ERROR',
  NEW_RESET_MERCHANT_USER_PASSWORD_RESET: 'NEW_RESET_MERCHANT_USER_PASSWORD_RESET',
  RESET_TOKEN_DATA: 'RESET_TOKEN_DATA',
  RESETTING_TOKEN_DATA: 'RESETTING_TOKEN_DATA',
  RESET_TOKEN_DATA_SUCCESS: 'RESET_TOKEN_DATA_SUCCESS',
  RESET_TOKEN_DATA_FAILED: 'RESET_TOKEN_DATA_FAILED',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',

  // GET REDIRECT URL
  FETCH_REDIRECT_URL: 'FETCH_REDIRECT_URL',
  FETCHING_REDIRECT_URL: 'FETCHING_REDIRECT_URL',
  FETCHED_REDIRECT_URL: 'FETCHED_REDIRECT_URL',
  FETCH_REDIRECT_URL_FAILED: 'FETCH_REDIRECT_URL_FAILED',
  newResetPassword: (payload) => ({
    type: actions.NEW_RESET_MERCHANT_USER_PASSWORD,
    payload,
  }),
  resetErrorMessageHandler: () => ({
    type: actions.RESET_ERROR_MESSAGE,
    payload: null,
    isError: false,
  }),
  newResetPasswordReset: () => ({
    type: actions.NEW_RESET_MERCHANT_USER_PASSWORD_RESET,
  }),

  resetTokenData: (payload) => ({
    type: actions.RESET_TOKEN_DATA,
    payload,
  }),
  fetchRedirectUrl: () => ({
    type: actions.FETCH_REDIRECT_URL,
  }),
};
export default actions;
