import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { get, PUT } from '@iso/lib/helpers/resApiRequestor';
// import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';

// const apiUrl = process.env.REACT_APP_API_URL;

// let headers = {
//   'Accept': 'application/json',
//   'Content-Type': 'application/json',
// };
export function* newResetMerchantUserPassword() {
  yield takeEvery('NEW_RESET_MERCHANT_USER_PASSWORD', function* (payload) {
    try {
      yield put({
        type: actions.NEW_RESETTING_MERCHANT_USER_PASSWORD,
      });
      const apiResult = yield newResetMerchantUserPasswordRequest(payload.payload.newData, payload.payload.id);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS,
          result: result ? result.data : null,
        });
      } else {
        yield put({
          type: actions.NEW_MERCHANT_USER_PASSWORD_RESET_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.NEW_MERCHANT_USER_PASSWORD_RESET_ERROR,
      });
    }
  });
}

export function* resetTokenData() {
  yield takeEvery('RESET_TOKEN_DATA', function* (payload) {
    try {
      const apiResult = yield resettingTokenData(payload.payload);
      const result = apiResult.data;
      console.log('API_RESULT_RESET_TOKEN_DATA', result);
      yield put({
        type: actions.RESETTING_TOKEN_DATA,
      });
      if (result.success) {
        yield put({
          type: actions.RESET_TOKEN_DATA_SUCCESS,
          result: result ? result.data : null,
        });
      } else {
        yield put({
          type: actions.RESET_TOKEN_DATA_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.RESET_TOKEN_DATA_FAILED,
      });
    }
  });
}

export function* getRedirectUrl() {
  yield takeEvery('FETCH_REDIRECT_URL', function* () {
    console.log('ENTER_SAGA 1');
    try {
      const apiResult = yield fetchRedirectUrl();
      console.log('FETCH_URL', apiResult);
      const result = apiResult.data;
      console.log('FETCH_URL', result);
      yield put({
        type: actions.FETCHING_REDIRECT_URL,
      });
      yield put({
        type: actions.FETCHED_REDIRECT_URL,
        redirectUrl: result.redirect_login,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_REDIRECT_URL_FAILED,
      });
      // console.log('FETCH_REDIRECT_URL_FAILED');
    }
  });
}

function fetchRedirectUrl() {
  // console.log('ENTER_SAGA 2', apiUrl);
  // return fetch(`${apiUrl}resettokens/get_reset_password_url`, {
  //   headers: headers,
  //   method: 'get',
  // });
  return get(`resettokens/get_reset_password_url`);
}
function newResetMerchantUserPasswordRequest(data, token) {
  return PUT(`authenticate/new_reset_password/${token}`, data);
}
function resettingTokenData(id) {
  return get(`resettokens/get_data_by_token/${id}`);
}
export default function* rootSaga() {
  yield all([
    fork(resetTokenData),
    fork(newResetMerchantUserPassword),
    fork(getRedirectUrl),
  ]);
}
