import actions from './actions';
// import storeActions from '../store/actions';

const initState = {
  idToken: null,
  errorMessage: null,
  // signupMessage: null,
  forgotMessage: null,
  modules: null,
  customModules: null,
  roles: null,
  isLoading: false,
  resetSuccess: false,
  identity: null,
  stores: null,
  // merchantId: null,
  // topic: null,
  // secondTimer: 0,
  env: null,
  // mqttToken: null,
  forbiddenRequest: false,
  // kickedOut: false,
  // preRegistered: false,
  // customAuthorizations: null,
  // showKyc: false,
  disabledModules: ['CORPORATE_STORE_USER', 'CORPORATE_ADDRESS_BOOK', 'POS_WALLET'],
  disabledSubmodules: ['MERCHANT_BATCH_UPLOAD', 'CORPORATE_ACCT_DASHBOARD', 'CORP_ACCOUNT_BANK_RECON', 'DOCS_ACCESS'],
  // merchantList: null,
  // selectedMerchant: null,
  // selectedMerchantName: null,
  // fetchingRedirectUrl: false,
  // redirectUrl: null,
  // redirectRegistrationSuccessData: null,
  // redirectSendingEmail: false,
  // redirectSendingSuccess: false,
  // redirectSendingFailed: false,
  // redirectSendingMessage: null,
  fetchingResetPassUrl: false,
  resetPassUrl: null,
  userId: null,
  otpBanner: false,
  otpEmail: null,
  otpMerchantId: null,
  isSavingOtp: false,
  otpErrorMessage: null,
  otpModal: false,
  otpData: null,
  otpLoading: false,
  verifyOtpLoading: false,
  verifyOtpData: null,
  otpAuthToken: null,
  isErrorVerifyOtp: false,
  otpMessage: null,
  isResendingOtp: false,
  verifyOtpMessage: null,
  limitReachMessage: null,
  otpModule: null,
};

export default function authReducer(state = initState, action) {

  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      // const newRegistered = JSON.parse(action.modules).filter((el) => {
      //   return el.alias === 'PRE_REG_GET_STARTED';
      // }).length > 0;
      // const identity = JSON.parse(action.identity);
      // let show = false;
      // if (identity.userSettings) show = true;

      // sanitize module and remove disabled modules
      // const mods = JSON.parse(action.modules).filter((el) => {
      //   const submods = el.submodules.filter((elB) => {
      //     return state.disabledSubmodules.indexOf(elB.alias) < 0;
      //   });
      //   el.submodules = submods;
      //   return state.disabledModules.indexOf(el.alias) < 0;
      // });

      //   return {
      //     ...state,
      //     idToken: action.token,
      //     identity: JSON.parse(action.identity),
      //     modules: mods,
      //     stores: JSON.parse(action.stores),
      //     // merchantId: action.merchantId,
      //     // customAuthorizations: JSON.parse(action.customAuthorizations),
      //     customModules: JSON.parse(action.customModules),
      //     roles: JSON.parse(action.roles),
      //     errorMessage: null,
      //     // topic: action.topic,
      //     env: action.env,
      //     // preRegistered: newRegistered,
      //     // merchantList: action.merchantList ? JSON.parse(action.merchantList) : [],
      //     // selectedMerchant: action.selectedMerchant,
      //     // selectedMerchantName: action.selectedMerchantName,
      //   };
      // case actions.FULLY_REGISTERED:
      // console.log('FULLY_REGISTERED', action);
      // sanitize module and remove disabled modules
      const mods = JSON.parse(action.modules).filter((el) => {
        const submods = el.submodules.filter((elB) => {
          return state.disabledSubmodules.indexOf(elB.alias) < 0;
        });
        el.submodules = submods;
        return state.disabledModules.indexOf(el.alias) < 0;
      });
      return {
        ...state,
        identity: JSON.parse(action.identity),
        idToken: action.token,
        modules: mods,
        // stores: JSON.parse(action.stores),
        // merchantId: action.merchantId,
        // customAuthorizations: JSON.parse(action.customAuthorizations),
        customModules: JSON.parse(action.customModules),
        // roles: JSON.parse(action.roles),
        errorMessage: null,
        // topic: action.topic,
        // preRegistered: false,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        // kickedOut: false,
        errorMessage: action.error,
      };
    case actions.CLEAR_ERROR:
      return {
        ...state,
        errorMessage: null,
        // signupMessage: null,
      };
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case actions.SIGNUP:
      return {
        ...state,
        isLoading: action.loading,
      };
    case actions.SIGNUP_ERROR:
      return {
        ...state,
        // signupMessage: action.error,
      };
    case actions.FORGOT_ERROR:
      return {
        ...state,
        forgotMessage: action.error,
      };
    case actions.SUBMODULES:
      // sanitize module and remove disabled modules
      // console.log(action)
      const mods3 = action.payload.filter((el) => {
        const submods = el.submodules.filter((elB) => {
          return state.disabledSubmodules.indexOf(elB.alias) < 0;
        });
        el.submodules = submods;
        return state.disabledModules.indexOf(el.alias) < 0;
      });
      return {
        ...state,
        modules: mods3,
      };
    case actions.FORGOT_PASSWORD:
      return {
        ...state,
        email: action.email
      };
    case actions.FORGOT_PASSWORD_DONE:
      return {
        ...state,
        resetSuccess: action.success,
        forgotMessage: null,
      };
    // case actions.RESET_SECOND_TIMER:
    //   return {
    //     ...state,
    //     secondTimer: action.secondTimer,
    //   };
    case actions.KICKED_OUT:
      return {
        ...state,
        // kickedOut: true,
      };
    case actions.KICKED_OUT_CLEAR:
      return {
        ...state,
        // kickedOut: false,
      };
    case actions.LOGIN_VALIDATOR:
      return {
        ...state,
        // mqttToken: action.token,
      };
    case actions.FORBIDDEN_REQUEST:
      return {
        ...state,
        forbiddenRequest: true,
      };
    case actions.LOGOUT:
      return {
        ...initState,
        // kickedOut: state.kickedOut,
        errorMessage: state.errorMessage,
      };
    //REDIRECT URL
    case actions.FETCH_REDIRECT_URL:
      return initState;
    case actions.FETCHING_REDIRECT_URL:
      return {
        ...state,
        fetchingRedirectUrl: true,
      };
    case actions.FETCHED_REDIRECT_URL:
      return {
        ...state,
        fetchingRedirectUrl: false,
        redirectUrl: action.redirectUrl,
      };
    case actions.FETCH_REDIRECT_URL_FAILED:
      return {
        ...state,
        fetchingRedirectUrl: false,
        redirectUrl: null,
      };
    case actions.FETCHING_RESET_PASSWORD_URL:
      return {
        ...state,
        fetchingResetPassUrl: true,
      };
    case actions.FETCHED_RESET_PASSWORD_URL:
      return {
        ...state,
        fetchingResetPassUrl: false,
        resetPassUrl: action.resetPassUrl,
      };
    case actions.FETCH_RESET_PASSWORD_URL_FAILED:
      return {
        ...state,
        fetchingResetPassUrl: false,
        resetPassUrl: null,
      };

    case actions.LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload,
      };
    case actions.REQUESTING_LOGIN_OTP:
      return {
        ...state,
        otpLoading: true,
      };
    case actions.REQUESTED_LOGIN_OTP:
      return {
        ...state,
        otpLoading: false,
        otpData: action.otpData,
        otpModal: action.otpModal,
        otpMessage: action.otpMessage,
        otpModule: action.otpModule,
      };
    case actions.SET_OTP_BANNER:
      return {
        ...state,
        otpBanner: action.otp_banner,
        otpEmail: action.otp_email,
        userId: action.user_id,
      };
    case actions.ERROR_REQUEST_LOGIN_OTP:
      return {
        ...state,
        otpLoading: false,
      };
    case actions.OTP_AUTH_TOKEN:
      return {
        ...state,
        otpAuthToken: action.payload,
      };
    case actions.SAVED_AGREE_OTP:
      return {
        ...state,
        otpBanner: false,
        isSavingOtp: false,
      };
    case actions.SAVING_AGREE_OTP:
      return {
        ...state,
        isSavingOtp: true,
      };

    case actions.CLEAR_OTP_DATA:
      return {
        ...state,
        otpMessage: null,
        otpModal: false,
        otpData: null,
        isErrorVerifyOtp: false,
        verifyOtpData: null,
        loginData: null,
        otpAuthToken: null,
        limitReachMessage: null,
        otpModule: null
      };
    case actions.CLEAR_OTP_MESSAGE:
      return {
        ...state,
        otpMessage: null,
        verifyOtpMessage: null,
        limitReachMessage: null,
      };
    case actions.RESENDING_LOGIN_OTP:
      return {
        ...state,
        // verifyOtpLoading: action.payload,
        isErrorVerifyOtp: action.isErrorVerifyOtp,
        isResendingOtp: action.payload,
        verifyOtpMessage: null,
        // otpData: null,
      };
    case actions.SET_SHOW_OTP_MODAL:
      return {
        ...state,
        otpLoading: false,
        otpData: null,
        otpModal: action.payload,
        verifyOtpLoading: false,
        isErrorVerifyOtp: false,
        otpEmail: null,
        isLoading: false,
        kickedOut: false,
        verifyOtpMessage: null,
      };
    case actions.VALIDATING_OTP:
      return {
        ...state,
        verifyOtpLoading: true,
      };
    case actions.VALIDATED_OTP:
      return {
        ...state,
        verifyOtpLoading: false,
        verifyOtpData: action.payload,
        isErrorVerifyOtp: action.isErrorVerifyOtp,
        // ADD THIS LOGIC IF HAS ERROR THE OTP MODAL STILL SHOW UNLESS THE LIMIT OF OTP REACH
        // otpModal: action.isErrorVerifyOtp ? true : false,
        otpModal: action.otpModal,
        verifyOtpMessage: action.verifyOtpMessage,
        limitReachMessage: action.limitReachMessage,
      };
    default:
      return state;
  }
}
