import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { get, postWithToken, PUT } from '@iso/lib/helpers/resApiRequestor';
// import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';

//
export function* fetchCompany() {
  yield takeEvery('FETCH_COMPANY', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_COMPANY,
      });
      const apiResult = yield fetchCompanyReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_COMPANY,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_COMPANY_FAILED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_COMPANY_ERROR,
      });
    }
  });
}
export function* fetchRoles() {
  yield takeEvery('FETCH_ROLES', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_ROLES,
      });
      const apiResult = yield fetchRolesReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_ROLES,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_ROLES_FAILED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ROLES_ERROR,
      });
    }
  });
}

//
/////

export function* searchAuditLogs() {
  yield takeEvery('SEARCH_AUDITLOGS', function* (payload) {
    try {
      const loadmore = payload.payload.loadmore;
      if (loadmore) {
        yield put({
          type: actions.LOADING_MORE_LOGS,
        });
      } else {
        yield put({
          type: actions.SEARCHING_AUDITLOGS,
        });
      }
      const apiResult = yield searchAudit(payload.payload);
      const result = apiResult.data;
      const currentList = payload.payload.currentLogs;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success) {
        yield put({
          type: actions.SEARCHED_AUDITLOGS,
          result: finalData,
          count: result.data.count,
          pagination: result.data.pagination
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_AUDITLOGS_FAILED,
      });
    }
  });
}
export function* fetchLookups() {
  yield takeEvery('FETCH_LOOKUPS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_LOOKUPS,
      });
      const apiResult = yield fetchLookupsReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_LOOKUPS,
          result: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_LOOKUPS_FAILED,
      });
    }
  });
}
export function* addLookups() {
  yield takeEvery('ADD_LOOKUPS', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_LOOKUPS,
      });
      const apiResult = yield addLookupsReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.ADDED_LOOKUPS,
          result: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_LOOKUPS_FAILED,
      });
    }
  });
}
export function* deleteLookups() {
  yield takeEvery('DELETE_LOOKUPS', function* (payload) {
    try {
      yield put({
        type: actions.DELETING_LOOKUPS,
      });
      const apiResult = yield deleteLookupsReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.DELETED_LOOKUPS,
          result: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_LOOKUPS_FAILED,
      });
    }
  });
}
export function* updateLookups() {
  yield takeEvery('UPDATE_LOOKUPS', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_LOOKUPS,
      });
      const apiResult = yield updateLookupsReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATED_LOOKUPS,
          result: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_LOOKUPS_FAILED,
      });
    }
  });
}
// RESPONSE CODE
// export function* fetchResponseCode() {
//   yield takeEvery('FETCH_RESPONSE_CODE', function* (payload) {
//     try {
//       yield put({
//         type: actions.UPDATING_LOOKUPS,
//       });
//       const apiResult = yield fetchResponseCodeReq(payload.payload);
//       const result = apiResult.data;
//       if (result.success) {
//         yield put({
//           type: actions.UPDATED_LOOKUPS,
//           result: result.data,
//         });
//       }
//     } catch (error) {
//       yield put({
//         type: actions.UPDATE_LOOKUPS_FAILED,
//       });
//     }
//   });
// }
export function* fetchResponseCode() {
  yield takeEvery('FETCH_RESPONSE_CODE', function* (payload) {
    try {
      const loadmore = payload.payload.loadmore;
      if (loadmore) {
        yield put({
          type: actions.LOADING_MORE_RESPONSE_CODE,
        });
      } else {
        yield put({
          type: actions.FETCHING_RESPONSE_CODE,
        });
      }
      const apiResult = yield fetchResponseCodeReq(payload.payload);
      const result = apiResult.data;
      const currentList = payload.payload.currentReponseCodeList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success) {
        yield put({
          type: actions.FETCHED_RESPONSE_CODE,
          result: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.FETCH_RESPONSE_CODE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_RESPONSE_CODE_ERROR,
      });
    }
  });
}
export function* addResponseCode() {
  yield takeEvery('ADD_RESPONSE_CODE', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_RESPONSE_CODE,
      });
      const apiResult = yield addResponseCodeReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.ADDED_RESPONSE_CODE,
          result: result.data.result,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.ADD_RESPONSE_CODE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_RESPONSE_CODE_ERROR,
      });
    }
  });
}
export function* updateResponseCode() {
  yield takeEvery('UPDATE_RESPONSE_CODE', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_RESPONSE_CODE,
      });
      const apiResult = yield updateResponseCodeReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATED_RESPONSE_CODE,
          result: result.data.result,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.UPDATE_RESPONSE_CODE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_RESPONSE_CODE_ERROR,
      });
    }
  });
}
export function* deleteResponseCode() {
  yield takeEvery('DELETE_RESPONSE_CODE', function* (payload) {
    try {
      yield put({
        type: actions.DELETING_RESPONSE_CODE,
      });
      const apiResult = yield deleteResponseCodeReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.DELETED_RESPONSE_CODE,
          result: result.data.result,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.DELETE_RESPONSE_CODE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_RESPONSE_CODE_ERROR,
      });
    }
  });
}
//
export function* fetchUsers() {
  yield takeEvery('FETCH_USERS', function* (payload) {
    try {
      const loadmore = payload.payload.loadmore;
      if (loadmore) {
        yield put({
          type: actions.LOADING_MORE_USERS,
        });
      } else {
        yield put({
          type: actions.FETCHING_USERS,
        });
      }
      const apiResult = yield fetchUsersReq(payload.payload);
      const result = apiResult.data;
      const currentList = payload.payload.currentReponseCodeList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success) {
        yield put({
          type: actions.FETCHED_USERS,
          result: finalData,
          pagination: result.data.pagination
        });
        if (!loadmore) {
          yield put({
            type: actions.SET_USER_DATA,
            payload: result.data.result[0],
          });
        }
      } else {
        yield put({
          type: actions.FETCH_USERS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_USERS_ERROR,
      });
    }
  });
}
export function* searchUser() {
  yield takeEvery('SEARCH_USER', function* (payload) {
    try {
        yield put({
          type: actions.SEARCHING_USER,
        });
      const apiResult = yield searchUserData(payload.payload);
      const result = apiResult.data;
      const loadmore = payload.payload.loadmore;
      const currentList = payload.payload.currentReponseCodeList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      console.log('Searchresult',result);
      if (result.success) {
        yield put({
          type: actions.SEARCHED_USER,
          result: finalData,
          pagination: result.data.pagination
        }); 
        if (!loadmore) {
          yield put({
            type: actions.SET_USER_DATA,
            payload: result.data.result[0],
          });
        }
      } else {
        console.log('failed True');
        yield put({
          type: actions.SEARCHING_USER_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCHING_USER_FAILED,
      });
    }
  });
}
export function* addUsers() {
  yield takeEvery('ADD_USERS', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_USERS,
      });
      const apiResult = yield addUsersReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.ADDED_USERS,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.ADD_USERS_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_USERS_ERROR,
      });
    }
  });
}
export function* updateUsers() {
  yield takeEvery('UPDATE_USERS', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_USERS,
      });
      const apiResult = yield updateUsersReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATED_USERS,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.UPDATE_USERS_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_USERS_ERROR,
      });
    }
  });
}
export function* deleteUsers() {
  yield takeEvery('DELETE_USERS', function* (payload) {
    try {
      yield put({
        type: actions.DELETING_USERS,
      });
      const apiResult = yield deleteUsersReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.DELETED_USERS,
          result: result,
        });
      } else {
        yield put({
          type: actions.DELETE_USERS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_USERS_ERROR,
      });
    }
  });
}
export function* resetPasswordUsers() {
  yield takeEvery('RESET_PASSWORD_USERS', function* (payload) {
    try {
      yield put({
        type: actions.RESETTING_PASSWORD_USERS,
      });
      const apiResult = yield resetPasswordUsersReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.RESET_PASSWORD_USERS_SUCCESS,
          result: result,
        });
      } else {
        yield put({
          type: actions.RESET_PASSWORD_USERS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.RESET_PASSWORD_USERS_ERROR,
      });
    }
  });
}

export function* fetchCompanies() {
  yield takeEvery('FETCH_COMPANIES', function* (payload) {
    try {
      const loadmore = payload.payload.loadmore;
      if (loadmore) {
        yield put({
          type: actions.LOADING_MORE_COMPANIES,
        });
      } else {
        yield put({
          type: actions.FETCHING_COMPANIES,
        });
      }
      const apiResult = yield fetchCompaniesReq(payload.payload);
      const result = apiResult.data;
      const currentList = payload.payload.currentReponseCodeList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success) {
        yield put({
          type: actions.FETCHED_COMPANIES,
          result: finalData,
          pagination: result.data.pagination
        });
        if (!loadmore) {
          yield put({
            type: actions.SET_COMPANIES_DATA,
            payload: result.data.result[0],
          });
        }
      } else {
        yield put({
          type: actions.FETCH_COMPANIES_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_COMPANIES_ERROR,
      });
    }
  });
}
export function* fetchCompaniesSearch() {
  yield takeEvery('FETCH_COMPANIES_SEARCH', function* (payload) {
    try {
        yield put({
          type: actions.FETCHING_COMPANIES_SEARCH,
        });
      const apiResult = yield fetchCompaniesSearchData(payload.payload);
      const result = apiResult.data;
      console.log('searchResult',result);
      const loadmore = payload.payload.loadmore;
      // const currentList = payload.payload.currentReponseCodeList;
      // const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success) {
        console.log('searchResult',result);
        yield put({
          type: actions.FETCHED_COMPANIES_SEARCH,
          result: result.data.result,
          pagination: result.data.pagination
        });
      }
      if (!loadmore) {
        yield put({
          type: actions.SET_COMPANIES_DATA,
          payload: result.data.result[0],
        });
      } 
      else {
        yield put({
          type: actions.FETCHING_COMPANIES_SEARCH_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_COMPANIES_SEARCH_FAILED,
      });
    }
  });
}

export function* addCompany() {
  yield takeEvery('ADD_COMPANY', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_COMPANY,
      });
      const apiResult = yield addCompanyReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.ADDED_COMPANY,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.ADD_COMPANY_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_COMPANY_ERROR,
      });
    }
  });
}
export function* updateCompany() {
  yield takeEvery('UPDATE_COMPANY', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_COMPANY,
      });
      const apiResult = yield updateCompanyReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATED_COMPANY,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.UPDATE_COMPANY_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_COMPANY_ERROR,
      });
    }
  });
}
export function* deactivateCompany() {
  yield takeEvery('DEACTIVATE_COMPANY', function* (payload) {
    try {
      yield put({
        type: actions.DEACTIVATING_COMPANY,
      });
      const apiResult = yield deactivateCompanyReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.DEACTIVATED_COMPANY,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.DEACTIVATE_COMPANY_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DEACTIVATE_COMPANY_ERROR,
      });
    }
  });
}
export function* deleteCompany() {
  yield takeEvery('DELETE_COMPANY', function* (payload) {
    try {
      yield put({
        type: actions.DELETING_COMPANY,
      });
      const apiResult = yield deletingCompanyReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.DELETED_COMPANY,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.DELETE_COMPANY_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_COMPANY_ERROR,
      });
    }
  });
}


export function* fetchIntegrators() {
  yield takeEvery('FETCH_INTEGRATORS', function* (payload) {
    try {
      const loadmore = payload.payload.loadmore;
      if (loadmore) {
        yield put({
          type: actions.LOADING_MORE_INTEGRATORS,
        });
      } else {
        yield put({
          type: actions.FETCHING_INTEGRATORS,
        });
      }
      const apiResult = yield fetchIntegratorsReq(payload.payload);
      const result = apiResult.data;
      const currentList = payload.payload.currentData;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success) {
        yield put({
          type: actions.FETCHED_INTEGRATORS,
          result: finalData,
          pagination: result.data.pagination
        });
        if (!loadmore) {

          yield put({
            type: actions.SET_INTEGRATORS_DATA,
            payload: result.data.result[0],
          });
          const data = {
            integrationId: result.data.result[0].Id,
          }
          const apiResultInfo = yield fetchIntegrationInfoReq(data);
          const resultInfo = apiResultInfo.data;
          if (resultInfo.success) {
            yield put({
              type: actions.FETCHED_INTEGRATION_INFO,
              result: resultInfo.data.result,
              pagination: resultInfo.data.pagination
            });
            if (!loadmore) {
              yield put({
                type: actions.SET_INTEGRATION_INFO_DATA,
                payload: resultInfo.data.result[0],
              });
            }
          } else {
            yield put({
              type: actions.FETCH_INTEGRATION_INFO_FAILED,
            });
          }
        }
      } else {
        yield put({
          type: actions.FETCH_INTEGRATORS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_INTEGRATORS_ERROR,
      });
    }
  });
}
export function* fetchIntegrationInfo() {
  yield takeEvery('FETCH_INTEGRATION_INFO', function* (payload) {
    try {
      const loadmore = payload.payload.loadmore;
      if (loadmore) {
        yield put({
          type: actions.LOADING_MORE_INTEGRATION_INFO,
        });
      } else {
        yield put({
          type: actions.FETCHING_INTEGRATION_INFO,
        });
      }
      const apiResult = yield fetchIntegrationInfoReq(payload.payload);
      const result = apiResult.data;
      const currentList = payload.payload.currentData;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success) {
        yield put({
          type: actions.FETCHED_INTEGRATION_INFO,
          result: finalData,
          pagination: result.data.pagination
        });
        if (!loadmore) {
          yield put({
            type: actions.SET_INTEGRATION_INFO_DATA,
            payload: result.data.result[0],
          });
        }
      } else {
        yield put({
          type: actions.FETCH_INTEGRATION_INFO_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_INTEGRATORS_ERROR,
      });
    }
  });
}
// export function* searchIntegrationInfo() {
//   yield takeEvery('SEARCH_INTEGRATION_INFO', function* (payload) {
//     try {
//       const loadmore = payload.payload.loadmore;
//       // if (loadmore) {
//       //   yield put({
//       //     type: actions.LOADING_MORE_INTEGRATION_INFO,
//       //   });
//       // } else {
//         yield put({
//           type: actions.SEARCHING_INTEGRATION_INFO,
//         });
//       // }
//       const apiResult = yield searchIntegrationInfoReq(payload.payload);
     
//       const result = apiResult.data;
//       const currentList = payload.payload.currentData;
//       const finalData =  result && result.data &&result.data.result.length !==0 ? result.data.result : currentList;
//       console.log('finalData',finalData);
//       if (result.success) {
//         yield put({
//           type: actions.SEARCHED_INTEGRATION_INFO,
//           result: finalData,
//           pagination: result.data.pagination
//         });
//         if (!loadmore) {
//           yield put({
//             type: actions.SET_INTEGRATION_INFO_DATA_SEARCHED,
//             payload:finalData[0],
//           });
//         }
//       } else {
//         yield put({
//           type: actions.SEARCHING_INTEGRATION_INFO_FAILED,
//         });
//       }
//     } catch (error) {
//       yield put({
//         type: actions.SEARCHING_INTEGRATION_INFO_FAILED,
//       });
//     }
//   });
// }

export function* updateIntegrationRole() {
  yield takeEvery('UPDATE_INTEGRATION_ROLES', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_INTEGRATION_ROLES,
      });
      const apiResult = yield updateIntegrationRoleReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATED_INTEGRATION_ROLES,
          result: result,
        });
      } else {
        yield put({
          type: actions.UPDATE_INTEGRATION_ROLES_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_INTEGRATION_ROLES_ERROR,
      });
    }
  });
}
// EMAILS AND GROUPS
export function* fetchGroupList() {
  yield takeEvery('FETCH_GROUPS', function* (payload) {
    const loadmore = payload.payload.loadmore;
    if (loadmore) {
      // console.log('fundSourcePayload 2');
      yield put({
        type: actions.LOADING_MORE_GROUPS,
      });
    } else {
      // console.log('fundSourcePayload 3');
      yield put({
        type: actions.FETCHING_GROUPS,
      });
    }
    try {
      // console.log('fundSourcePayload 4');
      const apiResult = yield getGroupData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_GROUPS-RESULT: ', result);
      const currentList = payload.payload.currentGroupList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_GROUPS,
          payload: finalData,
          pagination: result.data.pagination,
        });
        if (!loadmore) {
          yield put({
            type: actions.SELECTED_GROUP_DATA,
            selectedGroup: finalData[0],
          });
        }
      } else {
        yield put({
          type: actions.FETCH_GROUPS_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.FETCH_GROUPS_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchDocsUsers() {
  yield takeEvery('FETCH_DOCS_USERS', function* (payload) {
    try {
      const loadmore = payload.payload.loadmore;
      if (loadmore) {
        yield put({
          type: actions.LOADING_MORE_DOCS_USERS,
        });
      } else {
        yield put({
          type: actions.FETCHING_DOCS_USERS,
        });
      }
      const apiResult = yield fetchDocsUsersReq(payload.payload);
      const result = apiResult.data;
      const currentList = payload.payload.currentData;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success) {
        yield put({
          type: actions.FETCHED_DOCS_USERS,
          result: finalData,
          pagination: result.data.pagination
        });
        if (!loadmore) {
          yield put({
            type: actions.SET_DOCS_USERS_DATA,
            payload: result.data.result[0],
          });
          const data = {
            username: result.data.result[0].username,
          }
          const apiResultAccess = yield fetchDocsAccessReq(data);
          const resultAccess = apiResultAccess.data;
          if (result.success) {
            yield put({
              type: actions.FETCHED_DOCS_ACCESS,
              result: resultAccess.data,
            });
          } else {
            yield put({
              type: actions.FETCH_DOCS_ACCESS_FAILED,
              payload: resultAccess.message,
            });
          }
        }
      } else {
        yield put({
          type: actions.FETCH_DOCS_USERS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_INTEGRATORS_ERROR,
      });
    }
  });
}
export function* fetchDocsAccess() {
  yield takeEvery('FETCH_DOCS_ACCESS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_DOCS_ACCESS,
      });
      const apiResult = yield fetchDocsAccessReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_DOCS_ACCESS,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_DOCS_ACCESS_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_DOCS_ACCESS_ERROR,
      });
    }
  });
}
export function* updateDocsAccess() {
  yield takeEvery('UPDATE_DOCS_ACCESS', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_DOCS_ACCESS,
      });
      const apiResult = yield updateDocsAccessReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATED_DOCS_ACCESS,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.UPDATE_DOCS_ACCESS_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_DOCS_ACCESS_ERROR,
      });
    }
  });
}


export function* fetchEmailList() {
  yield takeEvery('FETCH_EMAILS', function* (payload) {
    yield put({
      type: actions.FETCHING_EMAILS,
    });
    try {
      const apiResult = yield getEmailData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_EMAILS-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_EMAILS,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.FETCH_EMAILS_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.FETCH_EMAILS_FAILED,
        payload: error,
      });
    }
  });
}

export function* addGroup() {
  yield takeEvery('ADD_GROUP', function* (payload) {
    yield put({
      type: actions.ADDING_GROUP,
    });
    try {
      const apiResult = yield postAddGroup(payload.payload);
      const result = apiResult.data;
      console.log('ADD_GROUP-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.ADDED_GROUP,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.ADD_GROUP_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.ADD_GROUP_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchLookupsStatus() {
  yield takeEvery('FETCH_LOOKUPS_STATUS', function* (payload) {
    // console.log('fetchStatusPayload', payload.payload);
    yield put({
      type: actions.FETCHING_LOOKUPS_STATUS,
    });
    try {
      const apiResult = yield fetchStatusLookupsData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_LOOKUPS_STATUS-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_LOOKUPS_STATUS,
          payload: result.data.result,
          // result: result.success,
        });
      } else {
        yield put({
          type: actions.FETCH_LOOKUPS_STATUS_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.FETCH_LOOKUPS_STATUS_FAILED,
        payload: error,
      });
    }
  });
}

export function* editGroupDetails() {
  yield takeEvery('EDIT_GROUP_DATA', function* (payload) {
    yield put({
      type: actions.EDITING_GROUP_DATA,
    });
    try {
      const apiResult = yield editGroup(payload.payload);
      const result = apiResult.data;
      console.log('EDIT_GROUP_DATA-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.EDITED_GROUP_DATA,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.EDIT_GROUP_DATA_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.EDIT_GROUP_DATA_FAILED,
        payload: error,
      });
    }
  });
}

export function* deleteGroupDetails() {
  yield takeEvery('DELETE_GROUP_DATA', function* (payload) {
    yield put({
      type: actions.DELETING_GROUP_DATA,
    });
    try {
      const apiResult = yield deleteGroup(payload.payload);
      const result = apiResult.data;
      console.log('DELETE_GROUP_DATA-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.DELETED_GROUP_DATA,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.DELETE_GROUP_DATA_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.DELETE_GROUP_DATA_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchAllCompany() {
  yield takeEvery('FETCH_ALL_COMPANY', function* () {
    yield put({
      type: actions.FETCHING_ALL_COMPANY,
    });
    try {
      const apiResult = yield getCompanyData();
      const result = apiResult.data;
      console.log('FETCH_ALL_COMPANY-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_ALL_COMPANY,
          payload: result.data.result,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.FETCH_ALL_COMPANY_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.FETCH_ALL_COMPANY_FAILED,
        payload: error,
      });
    }
  });
}

export function* addEmail() {
  yield takeEvery('ADD_EMAIL', function* (payload) {
    yield put({
      type: actions.ADDING_EMAIL,
    });
    try {
      const apiResult = yield postAddEmail(payload.payload);
      const result = apiResult.data;
      console.log('ADD_EMAIL-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.ADDED_EMAIL,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.ADD_EMAIL_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.ADD_EMAIL_FAILED,
        payload: error,
      });
    }
  });
}

export function* editEmailDetails() {
  yield takeEvery('EDIT_EMAIL_DATA', function* (payload) {
    yield put({
      type: actions.EDITING_EMAIL_DATA,
    });
    try {
      const apiResult = yield editEmail(payload.payload);
      const result = apiResult.data;
      console.log('EDIT_EMAIL_DATA-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.EDITED_EMAIL_DATA,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.EDIT_EMAIL_DATA_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.EDIT_EMAIL_DATA_FAILED,
        payload: error,
      });
    }
  });
}

export function* deleteEmailDetails() {
  yield takeEvery('DELETE_EMAIL_DATA', function* (payload) {
    yield put({
      type: actions.DELETING_EMAIL_DATA,
    });
    try {
      const apiResult = yield deleteEmail(payload.payload);
      const result = apiResult.data;
      console.log('DELETE_EMAIL_DATA-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.DELETED_EMAIL_DATA,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.DELETE_EMAIL_DATA_FAILED,
          payload: result.message,
        });}
    } catch (error) {
      yield put({
        type: actions.DELETE_EMAIL_DATA_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchAuthRoles() {
  yield takeEvery('FETCH_AUTH_MANAGEMENT_ROLE', function* () {
    console.log()
    try {
      yield put({
        type: actions.FETCHING_AUTH_MANAGEMENT_ROLE,
      });
      const apiResult = yield fetchAuthRolesInfo();
      const result = apiResult.data;
      console.log('AUTH-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_AUTH_MANAGEMENT_ROLE,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_AUTH_MANAGEMENT_ROLE_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchModules() {
  yield takeEvery('FETCH_MODULES', function* () {
    console.log()
    try {
      const apiResult = yield fetchModulesInfo();
      const result = apiResult.data;
      console.log('CURRENCY-RESULT: ', result);
      yield put({
        type: actions.FETCHING_MODULES,
      });
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_MODULES,
          payload: result.data.filter(function (element) {
            return element.moduleName !== 'Individual Account'
        }),
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_MODULES,
        payload: error,
      });
    }
  });
}
export function* postAuthorizationRole() {
  yield takeEvery('POST_AUTHORIZATION_ROLE', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_AUTHORIZATION_ROLE,
      });
      const apiResult = yield postAuthorizationRoleInfo(payload.payload);
      const result = apiResult.data;
      console.log('POST_AUTHORIZATION_ROLE-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.POSTED_AUTHORIZATION_ROLE,
          payload: result.data,
          message: 'Successfully added a new Authorization Role.'
        });
      } else {
        yield put({
          type: actions.POSTING_AUTHORIZATION_ROLE_FAILED,
          message: 'Failed to save Authorization Role.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.POSTING_AUTHORIZATION_ROLE_FAILED,
        payload: error,
        message: 'Failed to save Authorization Role.',
      });
    }
  });
}

export function* fetchAuthRolesPrivilage() {
  yield takeEvery('FETCH_AUTHORIZATION_ROLE_PRIVILAGE', function* (payload) {
    console.log()
    try {
      yield put({
        type: actions.FETCHING_AUTHORIZATION_ROLE_PRIVILAGE,
      });
      const apiResult = yield fetchAuthRolesPrivilageInfo(payload.payload);
      const result = apiResult.data;
      console.log('AUTH-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_AUTHORIZATION_ROLE_PRIVILAGE,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_AUTHORIZATION_ROLE_PRIVILAGE_FAILED,
        payload: error,
      });
    }
  });
}
export function* updateAuthorizationRole() {
  yield takeEvery('UPDATE_AUTHORIZATION_ROLE', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_AUTHORIZATION_ROLE,
      });
      const apiResult = yield updateAuthorizationRoleInfo(payload.payload);
      const result = apiResult.data;
      console.log('UPDATE_AUTHORIZATION_ROLE-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.UPDATED_AUTHORIZATION_ROLE,
          payload: result.data,
          message: 'Successfully added a new Authorization Role.'
        });
      } else {
        yield put({
          type: actions.UPDATING_AUTHORIZATION_ROLE_FAILED,
          message: 'Failed to save Authorization Role.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATING_AUTHORIZATION_ROLE_FAILED,
        payload: error,
        message: 'Failed to save Authorization Role.',
      });
    }
  });
}
export function* postAuthorizationRolePrivilege() {
  yield takeEvery('POST_AUTHORIZATION_ROLE_PRIVILEGE', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_AUTHORIZATION_ROLE_PRIVILEGE,
      });
      const apiResult = yield postAuthorizationRolePrivilegeInfo(payload.payload);
      const result = apiResult.data;
      console.log('POST_AUTHORIZATION_ROLE-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.POSTED_AUTHORIZATION_ROLE_PRIVILEGE,
          payload: result.data,
          message: 'Successfully added a new Authorization Role.'
        });
      } else {
        yield put({
          type: actions.POSTING_AUTHORIZATION_ROLE_PRIVILEGE_FAILED,
          message: 'Failed to save Authorization Role.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.POSTING_AUTHORIZATION_ROLE_PRIVILEGE_FAILED,
        payload: error,
        message: 'Failed to save Authorization Role.',
      });
    }
  });
}
// EMAILS AND GROUPS
function getGroupData(data) {
  return get(`emailgroups/`, data, data.page, null, null);
}
function getEmailData(id) {
  return get(`emailgroups/${id}`);
}
function postAddGroup(data) {
  return postWithToken(`emailgroups/`, data);
}
function fetchStatusLookupsData(data) {
  return get(`lookups/`, data, null, data.group, null, null);
}
function editGroup(data) {
  return PUT(`emailgroups/${data.id}`, data);
}
function deleteGroup(data) {
  return PUT(`emailgroups/delete/${data.Id}`, data);
}
function getCompanyData(data) {
  // return get(`company/get_all_company/`, data, data.page, null, null);
  return get(`company/get_all_merchant/`);
}
function postAddEmail(data) {
  return postWithToken(`emailitems/`, data);
}
function editEmail(data) {
  return PUT(`emailitems/${data.Id}`, data);
}
function deleteEmail(data) {
  return PUT(`emailitems/delete/${data.Id}`, data);
}
// EMAILS AND GROUPS END

const fetchCompanyReq = () => {
  return get(`company/fetch/all/new`);
}
const fetchRolesReq = () => {
  return get(`roles/`);
}
function searchAudit(data) {
  return postWithToken(`auditlogs/search/transactions`, data);
}
function fetchLookupsReq() {
  return get(`lookups/fetch/all`);
}
function addLookupsReq(data) {
  return postWithToken(`lookups/new/`, data);
}
function deleteLookupsReq(data) {
  return postWithToken(`lookups/delete/`, data);
}
function updateLookupsReq(data) {
  return PUT(`lookups/new/${data.id}`, data);
}
function fetchResponseCodeReq(data) {
  const newData = {
    page: data.page,
    mid: data.mid,
  }
  return get(`responsecodes/get/new/`, newData, newData.page, null, null);
}
function addResponseCodeReq(data) {
  return postWithToken(`responsecodes/new/`, data);
}
function updateResponseCodeReq(data) {
  return PUT(`responsecodes/new/${data.Id}`, data);
}
function deleteResponseCodeReq(data) {
  return postWithToken(`responsecodes/delete/`, data);
}
function fetchUsersReq(data) {
  const newData = {
    searchValue: data.searchValue ? data.searchValue : '',
    page: data.page,
    mid: data.mid,
  }
  return get(`users/`, newData, newData.page, null, null);
}
function searchUserData(data) {
  const newData = {
    page: data.page,
    searchValue: data.searchValue,
  }
  return postWithToken(`users/search_user/`, newData, newData.page, null, null);
}
const addUsersReq = (data) => {
  return postWithToken(`authenticate/add_user/new/`, data);
}
const updateUsersReq = (data) => {
  return PUT(`authenticate/update_user/new/${data.Id}`, data);
}
const deleteUsersReq = (data) => {
  return postWithToken(`authenticate/delete/`, data);
}
const resetPasswordUsersReq = (data) => {
  return get(`authenticate/reset_password/${data}`);
}
const fetchCompaniesReq = (data) => {
  const newData = {
    page: data.page,
    mid: data.mid,
  }
  return get(`company/get_all_company/`, newData, newData.page, null, null);
}
const fetchCompaniesSearchData = (data) => {
  console.log('searchPayload',data);
   const newData = {
     page: data.page,
     searchData: data.searchData,
 }
  return postWithToken(`company/get_company_search/`, newData, newData.page, null, null);
}
const addCompanyReq = (data) => {
  return postWithToken(`company/create/new/`, data);
}
const updateCompanyReq = (data) => {
  return PUT(`company/update/new/${data.Id}`, data);
}
const deactivateCompanyReq = (data) => {
  return PUT(`company/change_status/${data.Id}`, data);
}
const deletingCompanyReq = (data) => {
  return PUT(`company/delete/${data.Id}`, data);
}
const fetchIntegratorsReq = (data) => {
  const newData = {
    page: data.page,
  }
  return get(`users/integrators/new/`, newData, newData.page, null, null);
}
const fetchIntegrationInfoReq = (data) => {
  let userPagination = {
    page: 1,
    limit: 20,
  }
  const newData = {
    page: data && data.page ? data.page : userPagination,
    integrationId: data.integrationId,
    searchValue: data.searchValue ? data.searchValue : ''
  }
  return get(`centralizedrequestv2/integration/info/`, newData, newData.page, null, null);
}
// const searchIntegrationInfoReq = (data) => {
//   let userPagination = {
//     page: 1,
//     limit: 20,
//   }
//   const newData = {
//     page: data && data.page ? data.page : userPagination,
//     integrationId: data.integrationId,
//     searchValue:data.searchValue,
//   }
//   console.log('searchDataInfoInteg',newData);
//   return postWithToken(`centralizedrequestv2/search/integration/info`, newData, newData.page, null, null);
// }

const updateIntegrationRoleReq = (data) => {
  return PUT(`integrationroles/ui/${data.Id}`, data);
}
function fetchAuthRolesInfo() {
  // console.log('ENTER_SAGA 2', data);
  return get(`roles/` );
}
function postAuthorizationRoleInfo(data) {
  console.log('AuthorizationRoleData', data);
  return postWithToken(`lookups/saveRole/`, data);
}
function fetchModulesInfo() {
  return get(`core-modules/system/modules`);
}
function updateAuthorizationRoleInfo(data) {
  console.log('AuthorizationCheckerdata', data);
  return PUT(`roles/${data.roleId}/authorization/${data.Id}`,data);
}
function postAuthorizationRolePrivilegeInfo(data) {
  console.log('AuthorizationCheckerdataPost', data);
  return postWithToken(`roles/${data.roleId}/authorization/`,data);
}
function fetchAuthRolesPrivilageInfo(id) {
  console.log('fetchAuthRolesPrivilageInfo', id);
  return get(`roles/${id}`);
}

const fetchDocsUsersReq = (data) => {
  const newData = {
    page: data.page,
  }
  return get(`authenticate/docs_users/`, newData, newData.page, null, null);
}
const fetchDocsAccessReq = (data) => {
  return get(`authenticate/username/new/${data.username}`);
}
const updateDocsAccessReq = (data) => {
  return PUT(`docs/update/${data.username}`, data);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCompany),
    fork(fetchRoles),
    fork(searchAuditLogs),
    fork(fetchLookups),
    fork(addLookups),
    fork(deleteLookups),
    fork(updateLookups),
    fork(fetchResponseCode),
    fork(addResponseCode),
    fork(updateResponseCode),
    fork(deleteResponseCode),
    fork(fetchUsers),
    fork(addUsers),
    fork(updateUsers),
    fork(deleteUsers),
    fork(resetPasswordUsers),
    fork(fetchCompanies),
    fork(addCompany),
    fork(updateCompany),
    fork(deactivateCompany),
    fork(deleteCompany),
    fork(fetchIntegrators),
    fork(fetchIntegrationInfo),
    fork(updateIntegrationRole),
    fork(fetchDocsUsers),
    fork(fetchDocsAccess),
    fork(updateDocsAccess),
    fork(fetchGroupList),
    fork(fetchEmailList),
    fork(fetchAuthRoles),
    fork(fetchModules),
    fork(postAuthorizationRole),
    fork(fetchAuthRolesPrivilage),
    fork(updateAuthorizationRole),
    fork(postAuthorizationRolePrivilege),
    fork(addGroup),
    fork(fetchLookupsStatus),
    fork(editGroupDetails),
    fork(deleteGroupDetails),
    fork(fetchAllCompany),
    fork(addEmail),
    fork(editEmailDetails),
    fork(deleteEmailDetails),
    fork(fetchCompaniesSearch),
    fork(searchUser),
    // fork(searchIntegrationInfo),
  ]);
}
